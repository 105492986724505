.siteListItem {
  display: grid;
  grid-gap: 1rem;
  border-bottom: 1px solid var(--ambient-basePlus75);
  user-select: none;
  padding-bottom: 0.625rem;
}

.siteListItemCollapsed {
  height: 2.125rem;
  overflow: hidden;
}

.table {
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  text-align: left;
}

.table th,
.table td {
  font-size: 0.75rem;
  vertical-align: top;
  margin: 0;
  padding: 0;
}

.table td {
  padding: 0.625rem;
}

.table th:last-child {
  text-align: center;
}

.table th,
.table td {
  padding-left: 0;
}

.action {
  text-align: center;
}

.actionHeader {
  word-spacing: 0.2rem;
}

.table thead th {
  font-weight: normal;
  vertical-align: bottom;
  border-bottom: 1px solid var(--ambient-basePlus75);
}

.filterRow {
  cursor: pointer;
}

.icon {
  margin-right: 0.5rem;
  color: var(--accent-base);
  min-width: 1rem;
  max-width: 1rem;
}

.messageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3.125rem;
}

.tableLoading {
  padding: 0.625rem;
  font-size: 0.75rem;
}

.iconContainer {
  display: flex;
  align-items: center;
  height: 1rem;
}

.actionContainer {
  display: grid;
  grid-template-columns: 20rem 1fr;
  grid-gap: 1rem;
  align-items: flex-start;
}

.siteSelectorContainer {
  display: flex;
  align-items: center;
}

.siteSelectorContainer > button {
  margin-right: 0.5rem;
}

.actionTargetTypeContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.actionTargetType {
  display: flex;
  align-items: center;
  padding-top: 0.44rem;
}

.actionTargetType label {
  margin-right: 1rem;
  text-transform: capitalize;
}

.removeSiteContainer {
  color: var(--pink-base);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkboxContainer {
  display: flex;
  flex-direction: column;
}

.deviceTypeContainer {
  display: flex;
  flex-direction: column;
}

.deviceTypeLabel {
  margin-left: 300px;
}

.grayText {
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: var(--ambient-basePlus75);
}

.errorCenterContainer {
  text-align: center;
}

.selectAllContainer {
  font-size: 0.8rem;
  position: relative;
  min-height: 0px;
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
  margin-bottom: -34px;
  margin-right: 10px;
}

.btnEnalbed {
  background: none;
  border: none;
  color: var(--accent-base);
  cursor: pointer;
}

.btnDisabled {
  color: #cbced1;
  cursor: not-allowed;
}
.errorContainer {
  font-size: 0.75rem;
  line-height: 17px;
  color: red;
}

.selectAllButton {
  background: none;
  border: none;
  color: var(--accent-base);
  cursor: pointer;
}
