.root {
  position: relative;
  margin-right: 2rem;
  cursor: pointer;
}

.image {
  object-fit: contain;
}

.deleteButton {
  color: var(--pink-base);
  position: absolute;
  right: 10px;
  top: 10px;
}
