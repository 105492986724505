.textAlignCenter {
  text-align: center;
  padding: 0;
  margin: 0;
}

.paddingBetweenCards {
  padding-bottom: 3rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

.mainCardTopPadding {
  padding-top: 3rem;
}

.mainCardGrid {
  display: flex;
  flex-direction: column;
}

.subCard {
  margin: 2.5rem 4rem 0 4rem;
}

.mainCardTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 0.125rem;
  font-size: 1rem;
  font-weight: 600;
  color: #333e48;
}

.remoteAccess {
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.tooltipContent,
.remoteAccessDisabled {
  cursor: disabled;
  color: var(--ambient-basePlus50);
}

.tooltipContent {
  font-size: 0.75rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.5em;
}

.tooltipSeparator {
  height: 1px;
  background-color: currentColor;
}

.remoteAccessUrl {
  font-weight: 600;
  color: #fff;
}

.online {
  color: var(--green-base);
}

.heartbeatOnly {
  color: var(--orange-base);
}

.offline {
  color: var(--pink-base);
}

.successToastMessage {
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: -2rem;
}

.successToastMessageCard {
  display: flex;
  padding: 0 1rem;
  align-items: center;
  justify-content: center;
  height: 2rem;
  background-color: var(--accent-basePlus75);
  gap: 0.5rem;
  font-size: small;
}

.successToastMessageIcon {
  color: var(--accent-base);
}

.failureToastMessage {
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: -2rem;
}

.failureToastMessageCard {
  display: flex;
  padding: 0 1rem;
  align-items: center;
  justify-content: center;
  height: 2rem;
  background-color: var(--orange-basePlus75);
  gap: 0.5rem;
  font-size: small;
}

.failureToastMessageIcon {
  color: var(--pink-base);
}

.toastWarning {
  color: var(--orange-base);
}

.statusDotOutter {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  right: -12px;
  bottom: 4px;
  overflow: hidden;
}

.statusDotInner {
  width: 100%;
  height: 100%;
  background-color: currentColor;
}

.online {
  color: var(--green-base);
}

.heartbeatOnly {
  color: var(--orange-base);
}

.offline {
  color: var(--pink-base);
}

.confirmModalContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 2rem 1rem;
  font-size: large;
  font-weight: 600;
}
