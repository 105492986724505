.bodyBorder {
  border: 1px solid var(--ambient-basePlus90);
}

.cardheader {
  display: grid;
  grid-template-columns: 97% 3%;
  align-items: center;
}

.menuDropdown {
  padding: 0 1rem;
}

.groupTitle {
  text-align: center;
  font-size: 0.625rem;
  border-bottom: 2px solid var(--accent-base);
}

.deltaValue {
  display: flex;
  flex-direction: column;
}

.customCardStyles {
  padding: 1rem;
  box-shadow: var(--shadow10) !important;
  border-radius: 0 !important;
}

.siteContainer {
  padding: 1rem 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.75rem;
  font-weight: 700;
}

.schedulerIcon {
  color: var(--accent-base);
  cursor: pointer;
}

.buttons {
  justify-content: flex-end;
}

.delay {
  width: 100%;
  overflow-y: hidden;
  font-size: 0.8rem;
}

.pinkText {
  color: var(--pink-base);
}

.iconContainer {
  padding-top: 0.3rem;
}

.eqTitle {
  white-space: nowrap;
}

.groupHeader {
  padding-right: 1rem !important;
}

.loader {
  border-color: white !important;
  border-right-style: inset;
  border-top-color: transparent !important;
}

.noData {
  padding: 0.5rem;
}

.invalidIcon {
  color: var(--ambient-basePlus50);
  cursor: default;
}
