.form {
  display: grid;
  grid-template-columns: 47% 48% 3%;
}

.inputGroup {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr;
  column-gap: 1rem;
  padding: 0.75rem 0;
}

.tooltip {
  font-size: 0.75rem;
  font-weight: 200;
}

.imageLink {
  width: 3%;
}