.thermostatFormLabel {
  display: flex;
  align-items: center;
  color: var(--ambient-basePlus25);
  font-size: 0.75rem;
}

.equipmentLabel {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
}

.uuidContainer {
  display: flex;
  align-items: center;
}

.displayName {
  font-size: 0.75rem;
  color: var(--ambient-basePlus25);
  padding-left: 0.75rem;
  width: 100%;
}

.mandatoryTxt {
  color: 'var(--ambient-basePlus50)';
  font-style: 'italic' !important;
}

.flexContainer {
  display: flex;
}

.leftContainer {
  width: 50%;
}

.rightContainer {
  width: 50%;
}
