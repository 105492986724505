.lookupPortal {
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.lookupDropdown {
  z-index: 1002;
  position: absolute;
  background: white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  font-size: 0.75rem;
}

.lookupList {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  max-height: 200px;
  overflow: auto;
  flex-direction: column;
  margin-top: 1rem;
  min-width: 200px;
}

.lookupItem {
  display: flex;
  gap: 1.2rem;
  align-items: center;
  padding: 0.8rem 1rem;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.lookupItem:last-child {
  border-bottom: none;
}

.selected {
  background: var(--accent-basePlus90);
}

.loader {
  margin: 0.6rem;
}
