.filterAndStat {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0.2rem;
  margin-bottom: 1.5rem;
}

.filters {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.filterContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* margin-bottom: 1rem; */
}

.tableContainer {
  margin-top: -1rem;
}

.marginTop {
  margin-top: 1rem;
}

.filterLevelThree {
  position: relative;
}

/* .subHeader {
  display: none;
} */

.subHeader {
  padding: -1rem 3rem 0 3rem;
  display: flex;
  justify-content: space-between;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 10px;
  color: #000000;
  position: absolute;
}

/*div[class*='Table_container'] {
   padding-top: 10px;
  border-top: 1px solid var(--ambient-basePlus75); 
}*/

.leftContent {
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  font-size: 0.875rem;
}

.headerHeading {
  color: #333e48;
  font-weight: 700;
  display: flex;
  justify-content: flex-end;
  font-size: 1.25rem;
  padding-right: 0.5rem;
}

.eproWarningWrapper {
  position: relative;
  margin-left: 4px;
  top: 2px;
}
