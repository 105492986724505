.selectContainer {
  display: flex;
  justify-content: center;
}

.selectButtonContainer {
  width: 110px;
  font-size: 0.8rem;
  line-height: 1.5;
  padding: 0.375rem 0.375rem 0.375rem 0.2rem;
}

.dropdownButtonContainer {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdownButtondisabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.testInProgressWar {
  font-family: 'Open Sans', sans-serif;
  display: flex;
  justify-content: center;
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  line-height: 1.2rem;
}

.warning {
  display: flex;
  flex-direction: row;
  font-style: normal;
  font-weight: 700;
  color: #ef740f;
  padding: 0 0.5rem;
}

.warningIcon {
  padding-right: 0.2rem;
}
@media (max-width: 768px), (max-device-width: 768px) {
  .testInProgressWar {
    text-align: inherit;
    padding: 0.5rem 0.4rem;
  }
}
