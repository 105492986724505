.content {
  padding: 0 3rem 3rem 3rem;
}

.cardContainer {
  margin-top: 3rem;
}

.cardGrid {
  display: grid;
  grid-template-columns: 1fr 4fr;
}

.cardTitle {
  padding-top: 0.125rem;
  margin-left: 0.9375rem;
}

.textAlignCenter {
  text-align: center;
}

.updateConfigText {
  padding: 1rem 4rem;
  text-align: center;
}

.header {
  display: flex;
  justify-content: space-between;
}

.title {
  display: flex;
  font-weight: bold;
  align-items: center;
  font-size: 1rem;
}

.subTitle {
  font-size: 0.875rem;
}

.liveStatusContainer {
  font-size: 0.75rem;
  display: grid;
  grid-template-columns: 8.5rem 15rem 8.5rem 15rem;
  grid-gap: 0.75rem 1rem;
}

.liveStatusContainer > div {
  padding: 0.375rem 0;
}

.liveStatusContainer > svg {
  padding-right: 1rem;
}

.phaseHeader {
  display: flex;
  align-items: center;
}

.cardListHeader > * > * > * {
  overflow: visible;
}

.signalStrengthContainer {
  display: flex;
  align-items: center;
  gap: 6px;
}
