.root {
  background-color: var(--ambient-base);
  width: 10rem;
  text-align: left;
  padding: 0.5rem;
}

.tooltip:hover {
  visibility: visible;
}

.secondaryText {
  color: var(--medium-base);
  font-size: 0.75rem;
  font-style: italic;
}

.toolTipWrapper {
  text-align: left;
  color: white;
  width: 15rem;
}

.tooltipText {
  cursor: default;
}

.nameText {
  font-weight: 600;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  color: var(--ambient-basePlus90);
  font-size: 0.9375rem;
}

.loader {
  margin-top: 0.25rem;
}

.timestampText {
  padding-right: 1rem;
  padding-bottom: 0.25rem;
}
