.tooltip {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.tooltipText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.875rem;
}

.tooltipLineEntry {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: 8rem;
}
