.alignCard {
  padding: 1rem;
}

.spaceBetweenCards {
  display: flex;
  margin: 1rem 0;
}

.alignDragAndDrop {
  margin: 1rem 2rem 1rem;
}

.title {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
}
