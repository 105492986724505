.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 9.333125rem;
  height: 9.333125rem;
  color: var(--accent-base);
  border: 2px dashed var(--ambient-basePlus75);
  cursor: pointer;
}
.input {
  display: none;
  width: 100%;
  height: 100%;
}

.text {
  font-size: 0.75em;
}
.root > div {
  align-self: center;
}
