.dropdownContainer {
  padding: 0.375rem 0;
}

.normalDropdownButton {
  border-bottom: 1px solid var(--shade-25);
  padding-bottom: 0.4rem;
  font-size: 0.8rem;
  gap: 0.3rem;
  align-items: center;
  transition: border-color 0.15s ease-in-out;
}

.normalDropdownButton:hover:not(.disabledDropdownButton):not(.errorDropdownButton) {
  border-bottom-color: var(--accent-base);
}

.normalDropdownButton svg {
  margin-left: 0.3rem;
}

.selectedDropdownButton {
  border-bottom: 1px solid var(--accent-base);
}

.disabledDropdownButton.disabledDropdownButton {
  color: var(--shade-25);
}

.placeholderDropdownButton {
  color: var(--shade-75);
}

.errorDropdownButton.errorDropdownButton {
  border-bottom: 1px solid var(--pink-base);
}

.itemsContainer {
  max-height: 7.5rem;
  overflow-y: auto;
}

.itemsContainer::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.itemsContainer::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.dropdownItem {
  font-family: inherit;
}

.dropdownItem:focus {
  outline: none;
}

.dropdownListWrapper {
  position: fixed;
  top: inherit;
  left: inherit;
  margin-top: 5px;
  overflow-x: hidden;
}

.dropdownListWrapper1 {
  position: absolute;
  top: inherit;
  left: inherit;
  margin-top: 5px;
  overflow-x: hidden;
}

.createTimetableButton {
  color: var(--accent-base);
  cursor: pointer;
  border-top: 3px solid var(--ambient-basePlus90);
  box-sizing: content-box;
}

.createTimetableButton:hover {
  background-color: var(--accent-basePlus90);
}

.createTimetableTitle {
  background-color: var(--accent-basePlus90);
  cursor: default;
}

.createTimetableLabel {
  margin-left: 0.3125rem;
}

.createTimetableForm {
  height: calc(100% - 2.5rem - 5px);
}

.selectListWithTimetableForm {
  position: fixed;
  top: 100px;
  width: 500px;
  height: 600px;
  max-height: 60vh;
  max-width: 50vw;
  overflow: hidden;
}

.customErrorText {
  font-size: 0.75rem;
  color: var(--pink-base);
}

.tabs {
  display: flex;
  text-align: center;
  cursor: pointer;
  padding-left: 0.3rem;
}

.activeTab {
  border-bottom: 2px solid var(--accent-base);
}

.inActiveTab {
  border-bottom: 2px solid #d9d9d9;
}

.button {
  background-color: white;
  color: black;
  font-weight: 400;
  font-size: 0.75rem;
}

.button:hover {
  background-color: white; /* Change this to the desired hover color */
  cursor: pointer;
}
