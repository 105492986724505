.root {
  position: absolute;
  width: 100%;
  height: calc(100% + 3rem);
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.loaderFromOrgLevel {
  position: fixed;
  width: 100%;
  height: calc(100% + 3rem);
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.title {
  font-size: 3.5rem;
  color: var(--accent-base);
}

.description {
  font-size: 0.875rem;
}

.progressBarContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 30vh;
}

.progressBar {
  width: 60%;
  height: 1.8rem;
  position: relative;
}

.remainingTime {
  font-size: 0.875rem;
  color: var(--shade-75);
  width: 50px;
  text-align: center;
}
