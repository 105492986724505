.circuitStatusNotAvailable {
  padding: 0.25rem;
}

.localOverrideActiveBackground {
  background: var(--ambient-basePlus75);
}

.localOverrideActiveText {
  color: var(--ambient-basePlus50);
}
