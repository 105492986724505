.root {
  display: grid;
  grid-template-columns: repeat(3, 275px);
}

.iconWrapper {
  width: 5rem;
  height: 5rem;
}

@media only screen and (max-width: 768px) {
  .root {
    grid-template-columns: repeat(3, 150px);
  }

  .iconWrapper {
    width: 3rem;
    height: 3rem;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1224px) {
  .root {
    grid-template-columns: repeat(3, 200px);
  }

  .iconWrapper {
    width: 4rem;
    height: 4rem;
  }
}

.hvacNote2 {
  color: black;
  font-weight: bold;
}

.hvacNote1 {
  padding-top: 0.2rem;
  color: black;
}
