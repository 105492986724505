.mainHeader {
  display: flex;
  margin-top: 0.5rem;
}

.mainHeaderText {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  color: #000000;
  border-bottom: 1px dashed rgba(51, 62, 72, 0.25);
}

.subHead {
  margin: 1rem 0;
  font-weight: 700;
  font-size: 0.875rem;
  color: var(--color-text);
}

.moreInfoText {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: var(--color-text);
  text-align: left;
  padding: 2rem;
}

.headerSection {
  display: grid;
  grid-template-columns:
    repeat(2, 1fr)
    [uploadedBill] 13.2rem
    [sop] 1fr
    [equipment] 2fr
    [edm-health] 1fr;
  margin: 2rem 1rem 0rem 2.5rem;
}

.tableHeader {
  display: flex;
  text-align: center;
  align-items: center;
  font-size: 0.625rem;
}

.historicalData {
  display: flex;
}

.dataPaddingLeft {
  padding-left: 0.5rem;
}

.historicalWarning {
  font-size: 0.625rem;
  padding: 0.4rem 0;
}

.border {
  gap: 2rem;
}

.equipmentColumns {
  display: grid;
  grid-template-columns: 0.9fr 1fr;
  margin-left: -0.5rem;
}

.errorText {
  color: var(--pink-base);
}

.fitContent {
  display: grid;
  grid-template-columns: 1fr 2fr;
  border-bottom: 1px solid #dadada;
  padding: 0.5rem 0;
}
.fitContent:last-child {
  border: none;
}

.cardRow {
  padding: 0 0.625rem;
  margin: 0.625rem;
}

.tableData {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  margin: 1rem;
  color: #333e48;
  display: grid;
  grid-template-columns:
    repeat(2, 1fr)
    [uploadedBill] 14rem
    [sop] 3fr
    [edm-health] 1fr;
}

.tableCell {
  display: flex;
  padding: 0.25rem;
}

.displayBlock {
  display: block;
  text-align: left;
}

.siteName {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
}

.siteAddress {
  font-size: 0.75rem;
}

.sopSubCell {
  display: block;
  text-align: start;
  position: relative;
}

.subText {
  font-size: 0.563rem;
}

.edmStatusOff {
  color: var(--orange-baseMinus10);
}

.cursor {
  cursor: pointer;
}

.alignEquipmentCells {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.equipmentTitle {
  margin: 0 0 0.5rem 0;
  grid-column: 1/2;
}

.equipmentMode {
  grid-column: 2/2;
}

.empyCellToALign {
  margin-right: 4rem;
}

.tooltipText {
  font-weight: normal;
  font-style: normal;
  font-size: 0.75rem;
}

.pagination {
  display: flex;
  justify-content: center;
}

.pageSelectWrapper {
  display: flex;
  align-items: center;
  font-size: 0.625rem;
}

.alignPageSelectionEl {
  width: max-content;
  font-size: 0.825rem;
  display: flex;
  position: inherit;
  right: 1rem;
  gap: 9px;
}

.rowLimitDropdown {
  min-width: 0;
  font-size: 0.825rem;
}

.filterSection {
  display: flex;
}

.warningWorkingMode {
  font-family: 'Open Sans', sans-serif;
  font-size: 0.75rem;
  color: var(--orange-baseMinus10);
}
