.alignCheckboxVerticalCenter {
  padding: 0.375rem 0;
}

.verticalAlign {
  display: flex;
  align-items: center;
}

.marginLeft {
  margin-left: 2rem;
}

.grayText {
  font-size: 0.75rem;
  color: var(--ambient-basePlus75);
}

.phasorDiagramContainer {
  display: flex;
  margin-top: 1rem;
  align-items: center;
}
