.root {
  margin-top: 1rem;
}

.chartFilter {
  display: flex;
  justify-content: flex-end;
  margin: 0.5rem 0;
}

.zoomResetContainer {
  margin-left: 0.5rem;
}
