.title {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
}

.automations {
  flex: 1 100%;
}

.automationName {
  color: var(--ambient-base);
  margin-bottom: 1rem;
}

.automationDescription {
  font-size: 0.9rem;
  color: var(--medium-base);
}

.seperator {
  height: 1px;
  width: auto;
  background-color: #dadada;
  margin: 2.5rem 0;
}

.realUploadElement {
  display: none;
}

.instruction {
  font-weight: 600;
  color: var(--ambient-base);
  font-size: 0.875rem;
  line-height: 2rem;
}

.content {
  margin: 2rem 2rem 0;
}

.contentFromModal {
  margin: 2.5rem auto 0;
}

.uploader {
  display: flex;
  margin-bottom: 2rem;
  height: 6.8rem;
}

.modalUploader {
  display: flex;
  margin-bottom: 2rem;
  height: 4.8rem;
}

.dragFile {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  margin: 0;
  font-size: 0.9rem;
  color: var(--ambient-base);
  border: 2px dashed var(--ambient-basePlus75);
}

.browseFileBox {
  box-sizing: border-box;
  font-weight: 600;
  user-select: none;
  align-items: center;
  display: inline-flex;
  width: auto;
  min-width: 4rem;
  justify-content: center;

  font-size: 0.75rem;
  line-height: 1.5;

  height: 4rem;
  padding: 0 2rem;
  margin: auto 2.75rem;
}

.browseFile {
  cursor: pointer;
  background-color: var(--accent-base);
  color: var(--color-white);
}

.browseFile:hover {
  background-color: var(--accent-baseMinus50);
}

.disabledBrowseFile {
  background-color: var(--ambient-basePlus75);
  opacity: 0.5;
  color: var(--ambient-basePlus);
}

.utilityBillDragAndDrop {
  background: #eaebec;
  box-shadow: 0 0 0 0.5rem #eaebec;
}

.noBoxShadow {
  box-shadow: none;
  height: 40%;
}

.alignActionsOfEdm {
  margin-left: -2rem;
  margin-right: -2rem;
}

.browseFiles {
  display: block;
  cursor: pointer;
}

.browseFilesText {
  color: var(--accent-base);
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
}

.cardFromModal {
  padding: 0 1.875rem 0 1.875rem;
}

.historyCard {
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  height: 30vh;
  overflow-y: scroll;
}
