.iframe {
  width: 100%;
  height: 72rem;
  border: none;
  box-shadow: var(--shadow10);
}

.lastUpdated {
  font-size: 1rem;
  color: var(--color-text-light);
  margin-bottom: 2rem;
}
