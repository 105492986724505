.root {
  padding-bottom: 2rem;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  border-bottom: 3px solid var(--ambient-basePlus90);
  margin-bottom: 1rem;
}

.title {
  font-size: 0.875rem;
  font-weight: 600;
}

.subHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.25rem;
  border-bottom: 2px solid var(--ambient-basePlus90);
}

.subHeaderTitle {
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--ambient-basePlus50);
}
