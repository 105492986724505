:root {
  --cell-border-spacing: 5rem;
  --cell-border-negative-spacing: -0.5rem;
  --cell-border-padding: 1rem;
}

.countHeaderCellAdjust {
  padding-left: 0.75rem;
}

.sopTimetableRow {
  display: flex;
  flex-direction: row;
}

.timeslotCell {
  /* height: 3rem; */
  padding-top: var(--cell-border-padding);
  padding-bottom: var(--cell-border-padding);
  margin-left: var(--cell-border-negative-spacing);
  margin-right: var(--cell-border-negative-spacing);
}

.occupiedTimeslotCell {
  border-bottom: solid var(--ambient-basePlus75) 1px;
}

.occupiedTimeslotCellFirstRow {
  padding-top: 0;
}

.timeSlotCellBorder {
  border-bottom: solid var(--ambient-basePlus75) 1px;
}

.cellColumn {
  display: flex;
  flex-direction: column;
}

.sopTitle {
  font-weight: bold;
  font-size: 0.875rem;
}

.sopDescription {
  font-weight: 400;
  font-size: 0.75rem;
}

.timeSlotRow {
  display: flex;
  flex-direction: column;
}

.sopRecommendation {
  display: inline;
  margin-left: 0.5vw;
  vertical-align: middle;
  color: var(--turquoise-base);
}

.recommendationTooltip .title {
  font-style: italic;
}

.recommendationTooltip .recommendation {
  font-size: 1rem;
  font-weight: 600;
  color: var(--turquoise-base);
}

.recommendationTooltip .timeperiodTitle,
.recommendationTooltip .timeperiod {
  font-size: 0.6rem;
  color: var(--color-greySeparator);
}

.marginRight {
  margin-right: 1rem;
}

.deleteButtonText {
  color: var(--pink-base);
}

.equipmentDetails {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.equipmentDetails:first-child {
  margin-top: 0;
}

.greyText {
  color: var(--ambient-basePlus50);
}

.boldText {
  font-weight: bold;
}
.secondaryInfoText {
  display: flex;
  align-items: center;
  font-size: 0.675rem;
}

.paddingBetweenLimits {
  padding-top: 0.5rem;
}

.paddingRight {
  padding-right: 0.25rem;
}

.redText {
  color: var(--pink-base);
}

.tooltipContent {
  margin-left: 1rem;
  text-align: end;
  font-size: 0.8rem;
  font-weight: 600;
}

.toggle {
  display: inline-block;
}

.scheduleTypeCell {
  display: flex;
  align-items: center;
}

.clickableCell {
  cursor: pointer;
}

.gridColumnLeftSpace {
  padding-left: 0.25rem;
}

.sopTitleOrgLevel {
  font-weight: 600;
}
.gridColumnWidth1 {
  grid-column: auto / span 1 !important;
}

.gridColumnWidth2 {
  grid-column: auto / span 2 !important;
}

.cardTitle {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.1875rem;
}

.cardSubHead {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  margin: 0.5rem 0 1rem 0;
  color: #45555f;
}

.noSopData {
  text-align: center;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--pink-base);
}

.cardHeader {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.1875rem;
}

.alignNonSopText {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination {
  display: flex;
  justify-content: center;
}

.pageSelectWrapper {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 0.625rem;
}

.alignPageSelectionEl {
  font-size: 0.825rem;
  display: flex;
  position: absolute;
  right: 0;
}

.rowLimitDropdownContainer {
  width: 4rem;
  margin-left: 0.5rem;
}

.rowLimitDropdown {
  min-width: 0;
  font-size: 0.825rem;
}

.alignCardHeader {
  display: flex;
  position: relative;
}

.lastConfigSection {
  position: absolute;
  right: 0;
  display: flex;
  gap: 0.8rem;
}

.lastConfigSectionSite {
  display: flex;
  justify-content: flex-end;
  gap: 0.8rem;
}

.lastConfigText {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  padding: 0.3rem 0;
  border-bottom: 1px dashed #dadada;
  cursor: pointer;
  vertical-align: middle;
  margin-right: 0.5rem;
}

.configIcon {
  height: 1.56rem;
  width: 1.56rem;
  border: 0.1rem solid #00a1af !important;
  border-radius: 5px !important;
  color: #00a1af !important;
  position: relative;
  min-width: 0 !important;
}

.loader {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.label {
  box-sizing: border-box;
  background: rgba(217, 217, 217, 0.1);
  border: 1px solid #d8d8d8;
  font-size: 0.75rem;
  padding: 0.3rem 0.3rem 0 0;
  width: -webkit-fill-available;
  margin-top: 0.8rem;
  margin-right: 0.3rem;
}

.updateSent {
  display: inline-block;
  margin: 0.1rem 1rem;
  vertical-align: top;
}

.label div {
  display: inline-block !important;
}

.uploadIcon {
  position: absolute;
  left: 0.05rem;
  bottom: 0.05rem;
}

.disabled {
  height: 1.56rem;
  width: 1.56rem;
  border: 0.1rem solid var(--ambient-basePlus75) !important;
  border-radius: 5px !important;
  position: relative;
  min-width: 0 !important;
  color: var(--ambient-basePlus50);
  cursor: default;
}

.schedulerIcon {
  color: var(--accent-base);
  cursor: pointer;
}

.rowDropdownContent {
  background-color: rgba(234, 235, 236, 0.4);
  border-color: inherit;
  margin: 1rem 0;
}

.timetableContainer {
  width: 100%;
}

.timeTableWidth {
  width: 100rem;
}

.iconsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 2rem;
}
