.columns_10 {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
}

.columns_9 {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
}

.columns_8 {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
}

.columns_7 {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.columns_6 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.columns_5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.columns_4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.columns_3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.columns_2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.header {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 0.625rem;
  border-top: solid var(--ambient-basePlus75) 1px;
  border-bottom: solid var(--ambient-basePlus75) 1px;
  padding: 0.25rem;
}

.value {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0 0.5rem 0;
  font-size: 0.75rem;
}

.valueRow:nth-child(even) {
  /* below is var(--ambient-basePlus90) converted to rgba - needed to set opacity easily */
  background-color: rgb(203, 206, 209, 0.4);
}

.right {
  justify-content: right;
  padding-right: 12%;
}

.left {
  justify-content: left;
}
