.title {
  font-size: 0.875rem;
  font-weight: 600;
}

.description {
  margin-top: 0.5rem;
  font-size: 0.75rem;
}

.taskType {
  font-weight: bold;
}
