.addSpaceButton {
  border-top: 3px solid var(--ambient-basePlus90);
  box-sizing: content-box;
  font-family: inherit;
  padding-right: 1rem;
  padding-left: 1rem;
  color: var(--accent-base);
  font-size: 0.875rem;
  border: 0;
  cursor: pointer;
  height: 2.1rem;
  display: flex;
  align-items: center;
  background: none;
  margin-top: 0.3125rem;
  margin-bottom: 0.3125rem;
}

.spaceText {
  margin-left: 0.875rem;
}

.customSelectStyle {
  position: relative;
}

.formBody {
  padding: 1rem 1rem 0.5rem 1rem;
}

.formActionSection {
  box-sizing: border-box;
  background-color: var(--shade-05);
  width: 100%;
  min-height: 3.5rem;
  padding-right: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.selectItem {
  z-index: 999;
}

.dropdownContainer {
  position: absolute;
  top: 5vh;
  left: 8vw;
  width: 500px;
  max-height: 62vh;
  max-width: 50vw;
  overflow: hidden;
  background-color: var(--color-white);
  box-shadow: 0px 1px 2px var(--ambient-basePlus50);
  z-index: 1001;
}

.inDetailPage {
  position: absolute;
  top: 120px;
  left: 30vw;
  width: 500px;
  max-height: 62vh;
  max-width: 50vw;
  overflow: hidden;
  background-color: var(--color-white);
  box-shadow: 0px 1px 2px var(--ambient-basePlus50);
  z-index: 1001;
}
