.root {
  display: grid;
  grid-template-columns: 20px auto;
  grid-column-gap: 5px;
  align-items: center;
  text-align: left;
}

.address {
  max-width: 250px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
