#timetableVisualizationContainer {
  max-height: 12em;
  padding: 1.5em;
  font-size: small;
  color: #989fa4;
  box-shadow: inset 0px 4px 9px -5px;
}

#timetableVisualizationGridHorizontal {
  position: relative;
  margin-top: 20px;
  margin-left: 30px;
  flex: auto;
  box-shadow: inset 0 0 5px #a7a7a7;
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
}

#timetableVisualizationGridVertical {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

#timetableVisualizationBlocks {
  position: absolute;
}

.visualizationTitle {
  color: black;
  font-weight: 540;
  padding-bottom: 0.5em;
}

.verticalLineWithDash {
  position: relative;
  flex: auto;
  text-align: right;
  border-right: 1px dashed #cbced1;
}

.verticalLineNoDash {
  flex: auto;
  border: none;
}

.columnLabel {
  flex: 1 1 0px;
  transform: translate(0, -1.3em);
}

.dayRow {
  border-bottom: solid 1px #dadada;
  margin-bottom: -1px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 18px;
}

.dayRowLabel {
  transform: translate(-2.8em, 0);
  height: 100%;
}

.lastDayRow {
  border: none;
}

.specialRowDateChip {
  padding: 0.3em;
}
