.tabContent {
  padding-top: 2rem;
}

.marginRight {
  margin-right: 1rem;
}

.inlineDescriptionHeader {
  display: flex;
  align-items: center;
}

.greyText {
  color: var(--ambient-basePlus50);
  font-size: 0.75rem;
  font-style: italic;
}

.description {
  color: var(--ambient-basePlus50);
  font-size: 0.75rem;
  font-style: italic;
  cursor: pointer;
}

.modalFormContent {
  grid-template-columns: 8.5rem 20rem;
}

.inputFieldContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inputField {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
}

.input {
  width: 3rem;
}

.inputSeparator {
  margin: 0 0.75rem;
  font-size: 0.75rem;
}

.smallFont {
  font-size: 0.75rem;
  color: var(--ambient-basePlus50);
}

.tooltip {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.tooltipText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.875rem;
}

.tooltipLineEntry {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: 8rem;
}

.disabled {
  cursor: default;
  color: var(--ambient-basePlus50);
}

.dividerLine {
  border-bottom: 1px solid var(--ambient-basePlus75);
  margin: 0.5rem 0;
}

.localAdjustmentSwitch {
  font-size: 0.75rem;
}

.localAdjustmentGrey {
  /* !important needed to override the MUI color */
  color: var(--ambient-basePlus75) !important;
}

.advancedSettingsRow {
  display: flex;
  justify-content: space-between;
}

.bold {
  font-weight: bold;
}

.autoSetPointInputRow {
  display: flex;
  flex-direction: row;
}
