.cardTitleExtraPadding {
  margin-top: 1rem;
  margin-left: 0.9375rem;
}

.textAlignCenter {
  text-align: center;
}

.paddingBetweenCards {
  padding-bottom: 3rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

.mainCardTopPadding {
  padding-top: 3rem;
}

.mainCardGrid {
  display: grid;
  grid-template-columns: 1fr 4fr;
}

.mainCardTitle {
  padding-top: 0.125rem;
  margin-left: 0.9375rem;
}

.generalCardGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.offsetInputField {
  width: 5.25rem;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
}

.infoPadding {
  margin-left: 1rem;
}
