.modal > div {
  width: 50vw;
}

.actionsContainer {
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
}

.backButton {
  display: flex;
  align-items: center;
}

.backButtonText {
  padding-left: 0.6rem;
}

.modalTitle {
  margin: 2.5rem 2rem 1rem 2rem;
  font-size: 1.25rem;
}
