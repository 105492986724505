.cardContainer {
  margin: 2rem 1.5rem;
}

.cardTitle {
  padding-top: 0.125rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.headerLeftAlign {
  display: flex;
  align-items: center;
}

.energyProSelector {
  margin-left: 0.5rem;
}

.headerTitle {
  margin-top: -0.25rem;
}

.headerRightAlign {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.zoomResetContainer {
  margin-left: 0.5rem;
}

.chartResolutionFilterContainer {
  margin-right: 0.5rem;
}

.chartCard {
  margin: 1rem 0;
}

.chartCardContent {
  padding: 1.875rem 1.875rem 0 1.875rem;
}

.chardCardHeader {
  display: flex;
  justify-content: space-between;
}

.chartCardBreakerTitle {
  font-weight: bold;
}

.chartCardEquipmentTitle,
.chartCardRightAlign {
  font-size: 0.875rem;
}
