.stepWrapper {
  font-size: 0.75rem;
  line-height: 17px;
  color: var(--ambient-base);
  width: 100%;
}

.formHeading {
  border-bottom: 1px solid var(--base25);
  display: grid;
  grid-template-columns: 3fr 2fr 2fr 3fr 5fr;
  grid-column-gap: 1rem;
  padding-left: 1rem;
  padding-right: 0.5rem;
}

.stepHandling {
  margin-top: 1rem;
}

.stepHandling:last-child {
  margin-bottom: 1.25rem;
}

.addEscalation {
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.editIcon {
  margin-right: 0.5rem;
}

.errorContainer {
  font-size: 0.75rem;
  color: var(--pink-base);
}

.errorCenterContainer {
  text-align: center;
  color: red;
}

.loader {
  position: relative;
  margin-left: 40vw;
  margin-top: 5vh;
}
