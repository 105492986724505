.root {
  display: flex;
  flex-direction: row;
}

.operationalContainer {
  width: 65%;
}

.systemHealthContainer {
  width: 32%;
}

.verticalDivider {
  border-right: 1px solid var(--color-greySeparator);
  margin: 0 0.5rem;
}

.operationalTitle,
.systemHealthTitle {
  font-weight: bold;
}

.systemHealthTitle {
  margin-left: 1rem;
}

.operationalTiles {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.systemHealthTiles {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.wizardCard {
  padding: 1rem;
  width: 12vw;
  height: 12vw;
}

@media only screen and (max-width: 768px) {
  .wizardCardSize {
    width: 5vw;
    height: 5vw;
    padding: 20vh 5vh;
  }

  .operationalTiles {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .systemHealthTiles {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
