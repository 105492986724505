.root {
  width: 65vw;
  height: 75vh;
}

.modalHeader {
  padding: 2rem 2rem 1.5rem;
  margin: 0;
  font-size: 1.2rem;
}

.modalBody {
  padding: 0 2rem;
}

.modalBodyHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.contentHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.sentinelTitleContainer {
  display: flex;
  align-items: center;
}

.sentinelTitle {
  padding-left: 0.5rem;
}

.muteAllCheckbox {
  width: 20%;
}

.listHeader {
  width: 100%;
  font-size: 0.625rem;
}

.actionTargetType {
  padding-bottom: 0.5rem;
}

.actionTargetType label {
  margin-right: 1rem;
  text-transform: capitalize;
}

.errorMessage {
  padding-top: 0.25rem;
  font-size: 0.875rem;
}

.loader {
  display: flex;
  padding: 10rem;
  justify-content: center;
  align-items: center;
}
