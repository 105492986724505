.form {
  display: flex;
  max-width: 81.25rem;
}

.container {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(12, [col-start] 1fr);
}

.rowsContainer {
  grid-column: span 3;
  margin-top: 1rem;
}
