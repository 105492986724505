.moreTabs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.moreTabs > div > button > div {
  white-space: nowrap;
}

.moreTabsItem {
  display: flex;
  justify-content: flex-start;
  /* 1.25rem padding on either side of FilterDropdownBasicItem */
  width: calc(100% - 2.5rem);
}

.tabs {
  margin-bottom: 1rem;
}

.actionButtonContainer {
  margin-left: auto;
  padding-bottom: 0.25rem;
}

.filterDropdownContainer {
  padding-bottom: 0.25rem;
  z-index: 10;
}
