.lookupDropdown {
  position: absolute;
  background: white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  font-size: 0.75rem;
  z-index: 1002;
  min-width: 200px;
}

.dropdownTitle {
  font-weight: bold;
  padding: 1rem;
  border-bottom: 1px solid #ccc;
}

.lookupList {
  max-height: 300px;
  overflow-y: auto;
}

.lookupItem {
  display: flex;
  align-items: center;
  padding: 0.8rem 1rem;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.lookupItem:last-child {
  border-bottom: none;
}

.lookupItem:hover {
  background-color: var(--accent-basePlus90);
}
.lookupButton {
  height: 36px;
  padding: 0 12px;
  min-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 4px;
  line-height: 34px;
}

.deviceTitle {
  flex-grow: 1;
  margin: 0 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.signalStrength {
  margin-left: auto;
}

.selected {
  background: var(--accent-basePlus90);
}

.loader {
  display: flex;
  justify-content: center;
  padding: 1rem;
}

.noDevices {
  padding: 1rem;
  text-align: center;
}
