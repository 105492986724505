.biasField {
  display: flex;
  align-items: center;
}

.smallFont {
  font-size: 0.75rem;
  color: var(--ambient-basePlus50);
}

.luxValue {
  margin-top: 0.5rem;
  font-size: 0.65rem;
  font-weight: 400;
  white-space: nowrap;
}

.pinkText {
  color: var(--pink-base);
  display: flex;
  white-space: nowrap;
  padding-top: 0.5rem;
}

.relayButton {
  white-space: nowrap;
}

.noData {
  padding: 0.5rem;
}
