.container {
  margin-top: 1rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.title {
  font-size: 0.875rem;
}

.headerRightAlign {
  display: flex;
  align-items: center;
}

.zoomResetContainer {
  margin-left: 0.5rem;
}

.chartResolutionFilterContainer {
  margin-right: 0.5rem;
}
