.root {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.ONLINE {
  color: var(--green-base);
}

.OFFLINE {
  color: var(--pink-base);
}

.PARTIALLY_OFFLINE {
  color: var(--orange-base);
}
