.panelTitle {
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
}

.runConfigurationTest {
  color: #00a1af;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.5rem;
  border: 1px solid #00a1af;
  box-sizing: border-box;
}

.runConfigurationTestContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.buttonText {
  padding: 0 0.5rem;
}
