.remoteAccessButton {
  width: auto;
  height: auto;
}

.remoteAccessDisabled {
  display: inline-block;
  margin-top: 0.35rem;
}

.tooltipContent,
.remoteAccessDisabled {
  color: var(--ambient-basePlus50);
}

.remoteAccessUrl {
  font-weight: 600;
  color: #fff;
  line-break: anywhere;
}

.tooltipContent {
  display: flex;
  flex-direction: column;
  row-gap: 0.5em;
  font-size: 0.75rem;
}

.lastHeard {
  font-size: small;
  color: var(--ambient-basePlus50);
}

.green {
  color: var(--green-base);
  font-size: 0.9rem;
  font-weight: 600;
}

.orange {
  color: var(--orange-base);
  font-size: 0.9rem;
  font-weight: 600;
}

.red {
  color: var(--pink-base);
  font-size: 0.9rem;
  font-weight: 600;
}

.gray {
  color: var(--ambient-basePlus50);
  font-size: 0.9rem;
  font-weight: 600;
}

.outline {
  color: var(--color-white);
  font-size: 0.9rem;
  font-weight: 600;
}

.tooltipSeparator {
  height: 1px;
  background-color: currentColor;
}

.statusDotOutter {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  position: absolute;
  right: -12px;
  bottom: 4px;
  overflow: hidden;
}

.statusDotOutterOutline {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  position: absolute;
  right: -14px;
  bottom: 4px;
  overflow: hidden;
  border: 1px solid var(--ambient-basePlus50);
}

.statusDotInner {
  width: 100%;
  height: 100%;
  background-color: currentColor;
}
