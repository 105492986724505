.macAddressField {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.macAddressLookUpButton,
.loader {
  margin-top: -0.7rem;
}

.macAddressLookUpButton {
  font-weight: 500;
  flex-shrink: 0;
  width: 8rem;
}

.modelContent {
  margin-right: 3%;
}

.columnData {
  display: grid;
  grid-template-columns: 8rem 12rem;
  grid-gap: 0.6123rem 2.5rem;
  margin-bottom: 3rem;
}

.macAddressInput {
  width: 100%;
  margin-right: 0.5rem;
  flex: 1;
  min-width: 100%;
}

.cardContent {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 12%;
}

.loader {
  margin-left: 0.5rem;
}

.buildVersionField {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 0.2rem;
}

.warning {
  background-color: #ef740f25;
  padding: 0.66rem 0.2rem;
  width: 100%;
  font-size: 0.6rem;
  gap: 0.2rem;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-color: #ef740f75;
}

.warning > p {
  margin: 0;
}

.updating {
  background-color: #f5f5f5;
  padding: 0.4rem 0rem;
  width: 100%;
  font-size: 0.75rem;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  border-width: 1px;
  border-style: solid;
  border-color: #f5f5f5;
}

.updating > span {
  color: #cdcbcb;
  margin: 0;
}

.modelFromContent {
  display: grid;
  grid-template-columns: 8.5rem 15rem;
  grid-gap: 0.75rem 1rem;
  margin-bottom: 1rem;
}

@media (min-width: 1550px) {
  .modelContent {
    margin-right: 5%;
  }

  .columnData {
    display: grid;
    grid-template-columns: 8rem 18rem;
    grid-gap: 0.6123rem 5rem;
    margin-bottom: 3rem;
  }
}
