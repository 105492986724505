.cardTitleExtraPadding {
  margin-top: 1rem;
  margin-left: 0.9375rem;
}

.textAlignCenter {
  text-align: center;
}

.addSensor {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.paddingBetweenCards {
  padding-bottom: 3rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

.mainCardTopPadding {
  padding-top: 3rem;
}

.mainCardGrid {
  display: grid;
  grid-template-columns: 1fr 4fr;
}

.mainCardTitle {
  padding-top: 0.125rem;
  margin-left: 0.9375rem;
}

.generalCardGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
