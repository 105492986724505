.cardContainer {
  margin-bottom: 1rem;
}

.cardTitle {
  font-size: 1rem;
  font-weight: bold;
}

.columns {
  display: flex;
  flex-direction: row;
}

.fields {
  margin: 2rem 0 0 1.875rem;
}

.predefined {
  color: #aaa;
}
