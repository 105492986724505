.tooltip {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.text {
  left: 10% !important;
}

.tooltipText {
  font-size: 0.875rem;
  font-weight: 400;
}

.paddingTop {
  padding-top: 1rem;
}

.title {
  font-weight: 400;
}

.bold {
  font-weight: bold;
}
