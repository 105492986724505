.alignHeader {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.mainHead {
  border-right: 1px solid var(--ambient-basePlus90);
  padding-right: 1rem;
  font-size: 1.25rem;
}

.linkTo {
  font-size: 0.75rem;
  display: flex;
  align-items: center;
}

.customCardStyles.customCardStyles {
  padding: 1rem;
  box-shadow: var(--shadow10);
  border-radius: 0;
  overflow: scroll;
}

.cardHeadContainer {
  display: grid;
  grid-template-columns: 97% 3%;
  align-items: center;
}

.menuDropdown {
  padding: 0 1rem;
}

.siteContainer {
  font-size: 0.75rem;
  font-weight: 700;
}

.groupHeader {
  padding-right: 1rem !important;
}

.schedulerIcon {
  color: var(--accent-base);
  cursor: pointer;
}

.bodyBorder {
  border: 1px solid var(--ambient-basePlus90);
}

.tableContainer {
  overflow: auto;
}

.pinkText {
  color: var(--pink-base);
}

.addNewButton {
  padding: 0 0.5rem;
}

.buttons {
  padding-right: 1rem;
}

.filtersContainer {
  padding: 1rem 0;
}

.pagination {
  margin: 2rem auto 0 auto;
  display: flex;
  justify-content: center;
}

.Loader {
  margin: 3rem;
}

.loader {
  border-color: white !important;
  border-right-style: inset;
  border-top-color: transparent !important;
}

.redText {
  color: var(--pink-base);
}
