.root {
  display: grid;
  margin: 2.5rem;
  padding: 2rem;
  font-size: 0.875rem;
}

.cardContent {
  display: grid;
  grid-template-columns: repeat(3, 2fr) 1fr repeat(3, 2fr);
  margin: 1rem 0;
  grid-gap: 2rem;
  align-items: flex-end;
  font-size: 0.75rem;
}

.root:not(:last-child) {
  margin-bottom: 3rem;
}

.uuidField {
  display: block;
  padding-top: 0.3rem;
}

.balanceWidth {
  min-width: 12rem;
}

.noEntryText {
  color: var(--pink-base);
  font-size: 0.75rem;
  text-align: left;
  margin-left: -2rem;
  line-height: 1.5;
  padding: 0.375rem 1rem;
}
