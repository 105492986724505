.addSensorContainer {
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  justify-content: flex-end;
  font-weight: 400;
}

.buttonStyle {
  width: 6.5rem;
  padding-left: 1rem;
}

.inputStyle {
  width: 10rem;
}

.timestampStyle {
  font-size: 8;
}

.sensorTitleComponent {
  display: flex;
  flex-direction: column;
}

.boldHeader {
  font-weight: 700;
  font-size: 0.6rem;
}
