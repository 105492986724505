.tooltip {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tooltipText {
  font-size: 0.875rem;
  font-weight: 400;
}

.paddingTop {
  padding-top: 1rem;
  font-size: 0.8rem;
}

.mainText {
  text-wrap: wrap;
}

.alignText {
  max-width: 10rem;
}
