.root {
  display: grid;
  height: 3.75rem;
  background-color: var(--ambient-basePlus90);
}

.listColumns {
  grid-template-columns: 3rem auto 7.5rem;
}

.deleteColumns {
  grid-template-columns: 3rem auto 8.5rem;
}

.button {
  cursor: pointer;
  display: grid;
  align-items: center;
  text-align: center;
  height: 100%;
}

.buttonBack > svg {
  margin: 0 auto;
}

.delete,
.menuDropdownIcon {
  background-color: #f5f5f5;
  display: grid;
  align-items: center;
  justify-content: center;
  min-width: 3rem;
  width: fit-content;
  justify-self: end;
}

.deleteButtonText {
  color: var(--pink-base);
}

.dropdownMenu {
  background-color: #f5f5f5;
  display: flex;
  align-items: right;
  justify-content: center;
}

.dropdownMenu > div {
  transform: translateY(calc(50% - 10px));
}

.title {
  display: grid;
  width: auto;
  float: left;
  height: 100%;
  border-bottom: solid var(--accent-basePlus25) 2px;
  align-items: center;
  box-sizing: border-box;
  font-size: 0.875rem;
}

.dropdownTitle {
  font-size: 0.875rem;
  font-weight: 600;
}
