.loader {
  position: relative;
  margin-left: 40vw;
  margin-top: 35vh;
}

.tabs {
  margin-bottom: 1rem;
}

.root {
  background-color: var(--base5);
  padding: 2.8125rem;
  width: 100%;
  min-height: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.isNotActive {
  background-color: var(--color-white);
}

.metaContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: -14px;
}

.sentinelTypeIconContainer {
  position: absolute;
  width: 60px;
  height: 60px;
  left: -30px;
  top: 45px;
  background: linear-gradient(
    to right bottom,
    var(--turquoise-basePlus25),
    var(--turquoise-baseMinus50)
  );
  color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
}

.rolloutContainer {
  overflow-y: auto;
  height: 100%;
  max-height: 16rem;
  padding-top: 0.375rem;
}

.formContent {
  display: grid;
  grid-template-columns: 8.5rem 15rem;
  grid-template-rows: 1.5rem 1.5rem 1.5rem;
  grid-gap: 0.6125rem 1rem;
  grid-row-gap: 0;
}

.tabsContainer {
  padding-top: 1.5rem;
  margin-bottom: 2rem;
}

.actions {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  grid-gap: 1rem;
}

.controls {
  display: grid;
  grid-template-columns: 6.25rem 6.25rem;
  grid-gap: 0.5rem;
  justify-content: center;
}

.greenText {
  color: var(--accent-base);
}

.forceBlack {
  color: var(--ambient-base);
  align-items: center;
  justify-content: left;
}

.steps {
  flex-grow: 1;
}

.formWrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.error {
  margin: 0;
  grid-column-end: none;
}

.errorContainer {
  font-size: 0.75rem;
  line-height: 17px;
  color: red;
}

.bottomLine {
  border-bottom: 1px solid var(--ambient-basePlus75);
}

.deleteButton {
  font-weight: normal;
  color: var(--pink-base);
}

.deleteButton:hover {
  color: var(--pink-baseMinus25);
}

.deleteIcon {
  margin-right: 0.5rem;
}
