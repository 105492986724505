.invisibleButton {
  appearance: none;
  background: transparent;
  border: none;
  top: 0;
  right: 0;
  outline: none;
  z-index: 8000;
  margin: 0;
  padding: 0;
  color: inherit;
}

.invisibleButton:hover {
  opacity: 0.5;
  cursor: pointer;
}

.accessButton {
  display: flex;
  padding: 1rem 1.0625rem 0.5625rem 0;
  color: var(--accent-base);
  line-height: 1.25rem;
}

.accessButton > svg {
  padding-right: 0.5rem;
}
