.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 25rem;
  overflow-y: auto;
  padding: 0 5rem 1.5rem 5rem;
}

.contentContainerWithoutYml {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  padding: 0 5rem 1.5rem 5rem;
}

.title {
  margin: 3.75rem 6.25rem 1rem 5.5rem;
}

.syntaxHighlighter {
  font-size: 0.875rem;
}

.hashCode {
  font-size: 0.875rem;
  word-wrap: break-word;
  align-self: flex-start;
  padding-left: 0.6rem;
}
