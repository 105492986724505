.table {
  padding: 1.5rem;
  background-color: #f4f4f4;
  box-shadow: inset 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
}

.cardTitle {
  margin-bottom: 1rem;
  font-size: 0.75rem;
}
.card {
  padding: 0;
  border: 1px solid #dadada;
}

.content {
  border: 1px solid #dadada;
}

.loadingBar {
  display: grid;
  grid-template-columns: 85% 5%;
  grid-template-rows: auto;
  gap: 2rem;
  font-size: 0.75rem;
  align-items: center;
  justify-content: space-evenly;
  color: #00a1af;
  margin: 1rem;
}

.button {
  font-size: 0.75rem;
  font-weight: 400;
  color: #00a1af;
  padding: 0;
  margin: 0;
}

.checkbox {
  color: #00a1af;
}

.divider {
  height: 1rem;
}

.errorMessage {
  font-size: 0.75rem;
  padding: 0.5rem;
  display: flex;
  gap: 1rem;
  border: 1px solid var(--orange-base);
}

.paddingRight {
  padding-right: 0.5rem;
}

.results {
  display: flex;
  align-items: center;
}

.resultsHeaderStyle {
  font-size: 0.625rem;
  font-weight: 400;
}

.resultsIcon {
  color: #52c662;
}

.timestamp {
  padding-left: 1.5rem;
}

.testResults {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1.5fr) 1.5fr repeat(3, 1.5fr) repeat(
      3,
      1.5fr
    );
}

.current {
  padding-left: 1.4rem;
}

.withoutWarning {
  background-color: #efefef;
}

.warningBorder {
  border: 1px solid var(--orange-base);
}

.resultBorder {
  padding: 0.3rem 0 0.3rem 1rem;
  margin-left: -1rem;
  margin-bottom: 0.5rem;
}
