.root {
}

.header {
  display: flex;
  height: 3.75rem;
  background-color: var(--ambient-basePlus90);
  padding: 0 3rem;
}

.tabs {
  align-self: flex-end;
  flex-grow: 1;
}

.actions {
  align-self: center;
  white-space: nowrap;
}

.content {
  padding: 1.25rem 3rem;
}

.button {
  cursor: pointer;
  display: grid;
  margin: 0 0 0 -3rem;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 6rem;
}

.button > svg {
  margin: 0 auto;
}

.title {
  width: 100%;
  display: flex;
  align-items: center;
}
