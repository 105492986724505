.modal {
  width: 45vw;
  height: 85vh;
}

.confirmationModal {
  width: 40.5rem;
  height: 20rem;
}

.confirmationModalTitle {
  font-size: 1.5rem;
  margin: 3rem 4rem 2rem 4rem;
}

.confirmationModalContent {
  padding: 0 4rem;
}

.apiErrorMessage {
  margin-top: 2rem;
}

.pinkText {
  color: var(--pink-base);
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .modal {
    width: 80vw;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1224px) {
  .modal {
    width: 65vw;
  }
}
