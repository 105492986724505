.root {
  font-size: 0.625rem;
  width: 100%;
}

.pending {
  color: var(--alert-orange);
  font-weight: 600;
}

.uploading {
  color: var(--accent-base);
  font-weight: 600;
}

.failed {
  color: var(--alert-red);
  font-weight: 600;
}

.done {
  color: var(--alert-green);
  font-weight: 600;
}

.fileIcon {
  grid-area: fileIcon;
  text-align: right;
}

.status {
  grid-area: status;
}

.deleteButton {
  grid-area: deleteButton;
  color: var(--pink-base);
}

.progressBar {
  grid-area: progressBar;
}

.textButton {
  min-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.deleteIconButton:not([disabled]) {
  color: var(--pink-base) !important;
}

.fileDetail {
  grid-area: fileDetail;
}

.fileName {
  /* grid-area: fileName; */
  font-weight: 600;
  overflow-wrap: anywhere;
}

.fileSize {
  grid-area: fileSize;
  padding-top: 0.25rem;
}

.retryButton {
  grid-area: retryButton;
}

.gridDisplay {
  display: grid;
  column-gap: 0.75rem;
  row-gap: 0.1rem;
  align-items: center;
  grid-template-rows: auto;
}

.gridDisplay.vertical {
  grid-template-columns: fit-content(5%) auto fit-content(15%) fit-content(5%);
  grid-template-areas:
    'fileIcon fileDetail status deleteButton'
    'fileIcon fileSize retryButton .'
    'progressBar progressBar progressBar progressBar';
}

.gridDisplay.horizontal {
  grid-template-columns: fit-content(5%) auto fit-content(15%) fit-content(5%);
  grid-template-areas:
    'fileIcon fileDetail status deleteButton'
    'fileIcon fileSize retryButton .';
}

.gridDisplay.verticalFromEdm {
  grid-template-columns: fit-content(5%) auto fit-content(30%) fit-content(5%);
  grid-template-areas:
    'fileIcon fileDetail status deleteButton'
    'fileIcon fileSize retryButton .'
    'progressBar progressBar progressBar progressBar';
}

.gridDisplay.horizontalFromEdm {
  grid-template-columns: fit-content(5%) auto fit-content(30%) fit-content(5%);
  grid-template-areas:
    'fileIcon fileDetail status deleteButton'
    'fileIcon fileSize retryButton .';
}

/* .vertical > div[class*='progressBar'] {
  margin-top: 0.5rem;
}

.horizontal > div[class*='progressBar'] {
  margin-top: 0.5rem;
} */
