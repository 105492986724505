.root {
  margin-bottom: 4rem;
}

.settingsCard {
  padding-top: 2.5rem;
}

.marginTop {
  margin-top: 1rem;
}

.filterLevelThree {
  position: relative;
}

.subHeader {
  padding: -1rem 3rem 0 3rem;
  display: flex;
  justify-content: space-between;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 10px;
  color: #000000;
  position: absolute;
}

.filterContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.addOrgAccessButton {
  gap: 15px;
}

.orgAccessCell {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  min-height: 20px;
  padding: 10px;
}

.statusMessage {
  font-weight: 600;
}

.prospect {
  color: var(--blue-base);
}

.deleteIconButton:not([disabled]),
.alert {
  color: var(--pink-base) !important;
}

.installerModalContent {
  min-height: 340px;
}

.installerModalTabs {
  margin-bottom: 1rem;
}

.userAccessPrompt {
  font-size: 0.875rem;
}

.userAccess {
  align-items: center;
}

.userAccessTableContainer {
  grid-column: 1 / span 2;
}

.userAccessTableHeader {
  display: grid;
  grid-template-columns: 43fr 57fr;
  font-size: 0.7rem;
  font-weight: 600;
  border-top: 1px solid #dadada;
  border-bottom: 1px solid #dadada;
  padding: 0.2rem 0.5rem;
}

.userAccessTableContent {
  max-height: 200px;
  overflow-y: auto;
}

.predefined {
  display: inline-block;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  line-height: 1.5;
  font-size: 0.75rem;
  position: relative;
  color: var(--medium-base);
}

.modalTitle {
  position: relative;
  margin: 28px 75px 16px;
}

.modalTitleIcon {
  position: absolute;
  left: -45px;
  top: 2px;
}

.modalContent {
  padding: 0 30px 30px;
}
