.root {
  display: flex;
  align-items: center;
}

.durationCheckbox {
  padding-right: 0.5rem;
}

.durationPicker {
  width: 100%;
}

.datePickerContainer {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 1rem;
}

.applyButton {
  margin-left: auto;
}

.errorMessage {
  color: var(--pink-base);
  text-align: center;
  font-size: 0.75rem;
  margin: 0.5rem 1rem;
}

.datesText {
  display: flex;
  flex-wrap: wrap;
  color: var(--ambient-basePlus25);
  font-size: 0.6rem;
  padding-left: 2.8rem;
}
