.dropdown {
  overflow-y: hidden;
  box-shadow: 0 3px 8px var(--base25);
  height: 15rem;
  display: flex;
  flex-direction: column;
}

.errorChip {
  background-color: var(--pink-base);
}

.searchBox {
  flex-grow: 0;
  flex-shrink: 0;
}

.selectAll {
  color: var(--accent-base);
  font-size: 12px;
  user-select: none;
  cursor: pointer;
  margin: 0.875rem auto 0.2rem 1rem;
}

.dropdownList {
  overflow-y: auto;
}

.disabled {
  pointer-events: none;
}

.noItem {
  font-size: 14px;
  user-select: none;
  margin: 0.875rem auto;
  text-align: center;
}

.sectionHeader {
  margin: 0;
  padding: 1em;
}

.sectionHeader:not(:first-child) {
  border-top: solid var(--ambient-basePlus90) 2px;
}

.accessButton {
  width: 150px;
  gap: 5px;
}
