.root {
  row-gap: 0.25rem;
}

.uploadStatus {
  display: flex;
  justify-content: flex-end;
  gap: 1.5rem;
}

.uploading {
  color: var(--accent-base);
  font-weight: 600;
}
