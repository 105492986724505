.tooltipContent {
  font-size: 0.875rem;
  padding: 0.5rem;
  font-weight: 400;
}

.tooltipTitle {
  font-size: 0.75rem;
  color: var(--ambient-basePlus75);
}

.tooltipIcon {
  margin-left: 0.25rem;
  margin-top: 0.125rem;
}
