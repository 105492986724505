.cardTitle {
  font-size: 1rem;
  margin-bottom: 0.813rem;
  font-weight: bold;
}

.noData {
  font-size: small;
  text-align: center;
  margin: 0.8rem;
}

.DocumentTitle {
  margin-left: 0.5rem;
  align-self: center;
}

.buttonGroup {
  display: flex;
  justify-content: center;
  /* gap: 1rem; */
}

.trashIconButton:not([disabled]) {
  color: var(--pink-base) !important;
}

.cellContainer {
  overflow-wrap: anywhere;
}
