.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  z-index: 99999;
}

.imagesContainer {
  display: flex;
  flex-wrap: wrap;
}

.errorMessage {
  color: var(--pink-base);
  margin-top: 1rem;
  font-size: 0.875rem;
}
