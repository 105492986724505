.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.inputGroup {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr;
  column-gap: 1rem;
  padding: 0.75rem 0;
}
