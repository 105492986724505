.root {
  margin-top: 0.25rem;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 17rem);
  grid-auto-rows: min-content;
  grid-auto-flow: dense;
  grid-gap: 1.25rem;
}

.orgCardContainer {
  grid-column: 1 / -1;
  height: 9rem;
}

.orgCardContent {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

.orgCardLeftAlign {
  display: flex;
}

.orgTitle {
  font-weight: bold;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.orgAddress {
  font-size: 0.875rem;
}

.defaultSiteIcon {
  margin-top: -0.65rem;
  margin-right: 1rem;
}

.cardStyle {
  width: 17rem;
  height: 26rem;
}

.cardTitle {
  margin-top: 0rem;
  margin-bottom: 0.875rem;
}

.cardDescription,
.cardLinks {
  font-size: 0.75rem;
}

.cardIcon {
  display: flex;
  justify-content: flex-end;
}

.removeIconPadding {
  margin-top: -1rem;
  margin-bottom: -1.5rem;
}

.cardLinks {
  padding-bottom: 0.875rem;
  padding-top: 0.5rem;
  border-bottom: solid;
  border-color: var(--ambient-basePlus90);
  border-width: 0.125rem;
}

.linkText {
  font-weight: bold;
  color: var(--accent-base);
}

.linkText:hover {
  cursor: pointer;
}

button svg:not(:first-child) {
  margin-left: 5px;
}
