.cardTitle {
  margin-top: 0.5em;
  margin-bottom: 1em;
  font-size: 1rem;
  font-weight: bold;
}

.divider {
  width: 100%;
  border: 1px solid #dfe1e6;
}

.columnHeader {
  font-weight: bold;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  padding-top: 3px;
}

.button {
  font-size: 0.75rem;
  position: relative;
  padding-bottom: 0.5rem;
}

.tableGrid {
  display: grid;
  grid-template-columns: auto 200px;
  padding-top: 0;
}

.cellContainer {
  display: flex;
  align-items: flex-start;
  min-height: 26px;
  overflow-wrap: anywhere;
  vertical-align: middle;
  padding-top: 3px;
}

.fileIcon {
  width: 26px;
  height: 26px;
  margin-top: -3px;
}

.leftSpace {
  margin-left: 0.8em;
}

.actionItem {
  cursor: pointer;
}

.Valid {
  color: var(--alert-green);
}

.Invalid {
  color: var(--alert-red);
}

.Pending {
  color: var(--alert-orange);
}

.datePickers {
  display: inline-flex;
  align-items: center;
}

.separator {
  margin: 0 0.25rem;
}

/* TODO: Remove this once being released to Prod */
.legacy__filenameContainer {
  display: flex;
}

.legacy__DocumentTitle {
  margin-left: 0.5rem;
  font-weight: 600;
  cursor: pointer;
}
