.cardTitle {
  margin-bottom: 1em;
  font-size: 1rem;
  font-weight: bold;
}

.divider {
  width: 100%;
  border: 1px solid #dfe1e6;
}

.columnHeader {
  font-weight: bold;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.button {
  font-size: 0.75rem;
  position: relative;
  padding-bottom: 0.5rem;
}

.tableGrid {
  display: grid;
  grid-template-columns: auto 200px;
  padding-top: 0;
}

.sopTitle {
  font-weight: bold;
}
