.modal {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 2rem;
  width: 90%;
}

.mainText {
  font-size: 20px;
  font-weight: 600;
}

.subText {
  font-size: 14px;
  font-weight: 300;
}

.warningIcon {
  padding: 1.3rem 10px 0 0;
}
