.container {
  display: flex;
  font-size: 0.75rem;
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--color-greySeparator);
}

.containerFirst {
  border-top: 1px solid var(--color-greySeparator);
}

.buttonContainer {
  width: 16px;
  min-width: 16px;
  margin-right: 0.5rem;
}

.button {
  background: none;
  border: 0;
  cursor: pointer;
  padding: 0;
  padding-top: 0.5rem;
  margin: -0.375rem 0 0;
  display: flex;
  justify-content: center;
}

.button:focus {
  outline: none;
}

.button svg {
  flex-shrink: 0;
  display: block;
  color: var(--accent-base);
}

.content {
  flex-grow: 1;
}

.header {
  align-items: center;
}

/* .header,
.toggleContent {
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
} */

.header {
  /* display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr); */
  display: flex;
  justify-content: space-between;
}

.toggleContent {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.row {
  display: flex;
}
