.root {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.body {
  width: 100%;
  margin-top: 1rem;
}

.tableStyle {
  width: 100%;
}

.generateLink {
  cursor: pointer;
  font-weight: bold;
  color: var(--accent-base);
}
