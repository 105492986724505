.boldText {
  font-weight: 600;
  display: block;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.outputStatus {
  padding-left: 2rem;
}

.circuitStatus {
  padding-right: 7rem;
}
