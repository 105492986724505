.pageSelectWrapper {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 0.5rem;
  padding: 0.8rem 0 0 0;
  border-top: 0.8px solid #dadada;
}

.cardHeader {
  font-size: 0.875rem;
  font-weight: 600;
}

.alignPageSelectionEl {
  width: max-content;
  font-size: 0.825rem;
  display: flex;
  position: absolute;
  right: 0;
  gap: 9px;
}

.rowLimitDropdown {
  min-width: 0;
  font-size: 0.825rem;
}
