.wrapper {
  display: flex;
  flex-direction: column;
}

.container {
  padding: 10px 1.5rem;
  overflow-y: auto;
  flex-grow: 1;
  flex-shrink: 1;
}

.actions {
  box-sizing: border-box;
  background-color: var(--shade-05);
  width: 100%;
  padding: 1rem 0.5rem 2.5rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 0;
  flex-shrink: 0;
}
