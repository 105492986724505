.root {
  text-transform: capitalize;
}

.circle {
  background-color: currentColor;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 0.5rem;
  display: inline-block;
  margin-left: 0.5rem;
  margin-bottom: 1px;
}

.low {
  color: var(--notification-blue);
}

.medium {
  color: var(--notification-yellow);
}

.high {
  color: var(--notification-orange);
}

.critical {
  color: var(--notification-red);
}
