.switchWrapper {
  display: inline-block;
  position: relative;
  height: 1.25rem;
  width: 2.5rem;
  border-radius: 1.25rem;
  cursor: pointer;
}

.switchOn {
  background-color: var(--accent-base);
}

.switchOn::before {
  background-color: var(--color-white);
}

.switchOff {
  background-color: var(--ambient-basePlus50);
}

.switchOff::before {
  background-color: var(--color-white);
}

.switchCheckbox {
  display: none;
}

.switchWrapper::before {
  content: '';
  position: absolute;
  top: 1px;
  transform: translateX(1px);
  height: 1.125rem;
  width: 1.125rem;
  border-radius: 100%;
  transition: transform 0.1s;
}

.active::before {
  transform: translateX(calc(2.5rem - 1.125rem - 1px));
}

.disabled {
  background-color: var(--ambient-basePlus50);
}

.disabled::before {
  background-color: var(--color-white);
}
