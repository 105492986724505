.cardTitleExtraPadding {
  margin-top: 1rem;
  margin-left: 0.9375rem;
}

.textAlignCenter {
  text-align: center;
}

.addSensor {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.paddingBetweenCards {
  padding-bottom: 3rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

.mainCardTopPadding {
  padding-top: 3rem;
}

.mainCardGrid {
  display: flex;
  flex-direction: column;
}
.subCard {
  margin: 2.5rem 4rem 0 2.5rem;
}

.mainCardTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 0.125rem;
  margin-left: 0.9375rem;
  font-size: 1rem;
  font-weight: 600;
  color: #333e48;
}

.generalCardGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.rawOutputCard,
.actuatorMappingCard {
  display: flex;
}

.actuatorMappingCard {
  flex-direction: column;
}

.actuatorMappingTableContainer {
  width: 100%;
  margin-top: 1rem;
}

.localOverrideTitle {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  font-size: 0.8rem;
}

.localOverrideTimestamp {
  font-size: 0.7rem;
  align-self: center;
}

.localOverrideColorOutputStatus {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: var(--ambient-basePlus75);
}

.localOverrideColorCircuitStatus {
  color: #333e48;
}

table.rawOutputTable {
  border-collapse: collapse;
  text-align: center;
}

table td:nth-child(4n):not(:last-child).indexRow,
table td:nth-child(4n):not(:last-child).outputRow {
  padding-right: 1rem;
  border-right: 1px solid #ddd;
}

table td:nth-child(4n + 1).indexRow,
table td:nth-child(4n + 1).outputRow {
  padding-left: 1rem;
}

.indexRow {
  padding: 1rem 0.2rem;
  font-size: 0.875rem;
  border-bottom: 1px solid #ddd;
}

.outputRow {
  padding: 1rem 0.2rem;
  border-bottom: none;
}

.trueOutput {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: var(--accent-base);
}

.falseOutput {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  border: 0.1rem solid var(--ambient-basePlus75);
}

.centerAlign {
  display: flex;
  justify-content: center;
}

.circuitStatusOn {
  text-align: center;
  padding: 0.2rem 2rem;
  color: #333e48;
}

.circuitStatusOff {
  text-align: center;
  padding: 0.2rem 2rem;
  color: #333e48;
}

.controlModePadding {
  padding-left: 1rem;
}

.runConfigurationTest {
  color: #00a1af;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.5rem;
  border: 1px solid #00a1af;
  display: flex;
  align-items: center;
}

.runTestContainer {
  display: flex;
  justify-content: space-between;
}
.buttonText {
  padding: 0 0.5rem;
}
.testResultsWarning {
  padding-top: 1rem;
  padding-bottom: 0.1rem;
}
