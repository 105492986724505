.generalInfoHeader {
  padding-bottom: 0.5rem;
  border-bottom: 3px solid var(--ambient-basePlus90);
  margin-bottom: 1rem;
}

.button > svg {
  padding-right: 0.25rem;
}

.reportPeriodContainer,
.tooManyPeriodsMessage {
  display: flex;
  align-items: flex-end;
  font-size: 0.75rem;
  padding: 0.5rem 0;
}

.reportPeriodInput {
  width: 10rem;
  outline: none;
  text-align: center;
}

.colonSeparator {
  padding: 0 2rem;
}

.paddingRight {
  padding-right: 1rem;
}

.trashIcon {
  cursor: pointer;
  padding-left: 2rem;
  color: var(--pink-base);
}

.tooManyPeriodsMessage {
  font-size: 0.75rem;
  color: var(--pink-base);
  padding: 1rem 0;
}

input[type='time'] {
  font-size: 0.75rem;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}

.checkBoxContainer {
  display: flex;
  align-items: center;
}
