.inputField {
  width: 5rem;
}

.inputWithIcon {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
}

.marginLeft {
  margin-left: 1rem;
}

.marginRight {
  margin-right: 1rem;
}

.inlineDescriptionHeader {
  display: flex;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  align-items: center;
}

.greyText {
  color: var(--ambient-basePlus50);
  font-size: 0.75rem;
  font-style: italic;
}
