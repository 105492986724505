.modalContent {
  width: 70vw;
}

.deleteModal {
  padding: 1rem;
  text-align: center;
  font-size: 1rem;
}

.boldText {
  font-weight: bold;
}
