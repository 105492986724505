.workingModeButtonsContainer {
  display: flex;
}

.workingModeButton:not(:last-of-type) {
  margin-right: 1rem;
}

.updateSentButton {
  display: flex;
  align-items: center;
}

.loader {
  margin-left: 0.5rem;
}
