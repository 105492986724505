.loadingBar {
  display: grid;
  grid-template-columns: 30rem 10rem;
  grid-template-rows: auto;
  gap: 2.5rem;
  font-size: 1.2rem;
  align-items: center;
  color: #00a1af;
}

.pinkText {
  padding-top: 1rem;
  color: var(--pink-base);
  font-size: 0.875rem;
}

.fillColor {
  color: #00a1af;
}
