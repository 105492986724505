.modalContainer {
  min-width: 42rem;
}

.mainCardTopPadding {
  padding-top: 3rem;
}

.mainCardGrid {
  display: flex;
  flex-direction: column;
}

.subCard {
  margin: 0.6rem;
}

.mainCardTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 0.125rem;
  font-size: 1rem;
  font-weight: 600;
  color: #333e48;
}

.loadbar {
  justify-content: center;
  display: flex;
  padding-top: 2rem;
}
