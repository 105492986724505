.inputField {
  width: 5rem;
}

.inputWithIcon {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
}

.marginRight {
  margin-right: 1rem;
}

.biasFieldContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.biasField {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
}

.biasInput {
  width: 3rem;
}

.biasColon {
  margin: 0 0.75rem;
  font-size: 0.75rem;
}

.smallFont {
  font-size: 0.75rem;
  color: var(--ambient-basePlus50);
}

.marginRight {
  margin-right: 0.5rem;
}
