.cellRoot {
  border: none !important;
}

.rootcell {
  width: 100%;
}

.rootcell td {
  padding: 0;
  font-size: 0.85em;
}

.disabled td {
  color: var(--medium-base);
}

.rootcell th {
  padding: 0;
  font-weight: normal;
}

.tableHeaderDatePicker {
  border: none !important;
  width: 30%;
}

.tableHeaderDatePickerEdm {
  border: none !important;
  width: 46%;
}

.datePickersContinaer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.datePickersContainerInModal {
  display: flex;
}
.datePicker {
  width: 8.5rem;
  margin: 2px 0;
}

.datePicker:first-child {
  margin-right: 0.5rem;
}

.title {
  font-weight: 600;
  font-size: 1.3em;
}

.trashIcon {
  color: var(--pink-base);
  cursor: pointer;
}

.pending {
  color: var(--alert-orange);
  font-weight: 600;
}

.uploading {
  color: var(--accent-base);
  font-weight: 600;
}

.failed {
  color: var(--alert-red);
  font-weight: 600;
}

.done {
  color: var(--alert-green);
  font-weight: 600;
}

.bar {
  height: 0.64rem;
}
