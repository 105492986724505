.luxValueContainer {
  display: flex;
  flex-wrap: nowrap;
}

.luxValue {
  margin-top: 0.5rem;
  font-size: 10pt;
  white-space: nowrap;
  color: var(--ambient-basePlus50);
}

.updateFirmwareWarning {
  background-color: #ef740f25;
  padding: 0.66rem 0.2rem;
  width: 100%;
  font-size: 0.6rem;
  gap: 0.2rem;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-color: #ef740f75;
}

.modelContent {
  margin-right: 3%;
}

.columnData {
  display: grid;
  grid-template-columns: 8rem 12rem;
  grid-gap: 0.7rem 3.5rem;
  margin-bottom: 3rem;
}

.cardContent {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

@media (min-width: 1550px) {
  .modelContent {
    margin-right: 5%;
  }

  .columnData {
    display: grid;
    grid-template-columns: 8rem 15rem;
    grid-gap: 1rem 5rem;
    margin-bottom: 3rem;
  }

  .cardContent {
    gap: 6%;
  }
}
