.tooltip {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.margin {
  margin-left: 1rem;
}

.tooltipRoot {
  min-width: 8rem;
  font-size: 0.85rem;
}

.tooltipLineEntry {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: 8rem;
  font-size: 10pt;
  font-weight: 400;
}

.tooltipCommentLine {
  font-size: 10pt;
  margin-top: 0.5rem;
  font-weight: 400;
}

.marginLeft {
  margin-left: 0.5rem;
}

.title {
  font-weight: 400;
}

.paddingTop {
  padding-top: 1rem;
}

.text {
  left: 10% !important;
}
