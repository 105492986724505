.modal {
  width: 70vw;
}

.actionsContainer {
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
}

.backButton {
  display: flex;
  align-items: center;
}

.backButtonText {
  padding-left: 0.6rem;
}

.modalContent {
  display: flex;
  flex-direction: column;
}

.equipmentTitle {
  font-size: 0.875rem;
  color: var(--ambient-basePlus25);
}
