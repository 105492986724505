.pagination {
  margin: 2rem auto 0 auto;
  display: flex;
  justify-content: center;
}

.Loader {
  margin: 3rem;
}

.buttonContainer {
  display: flex;
}

.button {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0;
  width: 100%;
}

.hideButton {
  background-color: #e5f5f6;
}
