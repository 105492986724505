.root {
  margin-top: 1rem;
  padding: 1rem;
}

.warningBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--orange-basePlus90);
  color: var(--color-text);
  padding: 0.5rem;
  font-size: 0.75rem;
}

.warningBannerText {
  color: var(--orange-base);
  font-weight: 600;
}

.errorBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--pink-basePlus90);
  color: var(--color-text);
  padding: 0.5rem;
  font-size: 0.75rem;
}

.errorBannerText {
  color: var(--pink-base);
  font-weight: 600;
}

.warningBold {
  font-weight: 600;
}

.refreshButton {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  cursor: pointer;
  color: var(--accent-base);
  min-width: 10rem;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.energyProLabel {
  display: flex;
  width: 12rem;
}

.selectEnergyPro {
  display: flex;
  align-items: center;
}

.columnAction {
  color: var(--accent-base);
  cursor: pointer;
  border-bottom: none;
}

.energyDeviceRow {
  display: grid;
  grid-template-rows: repeat(3, 2.5rem [ct-port]);
}

.devicePortRow {
  display: flex;
  align-items: center;
}

.busDeviceTreeRoot {
  display: flex;
}

.busDeviceTreeChild {
  display: flex;
  height: 7.5rem;
}

.busDeviceRoot {
  font-size: 0.75rem;
}

.busDeviceHeader {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  align-items: center;
  font-weight: bold;
  background-color: var(--shade-05);
  border-top: 1px solid var(--ambient-basePlus90);
  border-bottom: 1px solid var(--ambient-basePlus90);
  margin-bottom: 0.5rem;
  margin-top: 1.25rem;
  cursor: pointer;
}

.energyProHeaderMargin {
  margin-right: 1rem;
}

.topSeparator {
  border-top: 1px solid var(--ambient-basePlus75);
  border-bottom: 1px solid var(--ambient-basePlus75);
}

.bottomSeparator {
  white-space: nowrap;
  text-overflow: ellipsis;
  border-bottom: 1px solid var(--ambient-basePlus75);
}

.accentBaseSeparator {
  border-bottom: 3px solid var(--accent-base);
}

.busDeviceContainer {
  max-height: 2000px;
  opacity: 1;
  animation: expand 0.3s linear;
}

.busDeviceContainer.hidden {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  animation: collapse 0.3s linear;
}

@keyframes collapse {
  0% {
    max-height: 2000px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    opacity: 0;
  }
}

@keyframes expand {
  0% {
    max-height: 0;
    opacity: 0;
  }
  100% {
    max-height: 2000px;
    opacity: 1;
  }
}

.busDeviceTableContainer {
  display: flex;
  width: 100%;
}

/* repeat(13, minmax(0, 1fr)); */
.busDeviceTable {
  font-size: 0.75rem;
  display: grid;
  grid-template-columns:
    [device] 8fr
    [port] 10fr
    [site-total] 3fr
    [breakers] 6fr
    [ct-type] 6fr
    [phase] 4fr
    [ct-polarity] 4fr
    [current] 3fr
    [voltage] 3fr
    [power-factor] 3fr
    [active-power] 4fr;
  grid-template-rows: repeat(1, auto-fill) repeat(auto-fill, 2.5rem);
  align-items: center;
}

.sensorBusTable {
  font-size: 0.75rem;
  display: grid;
  grid-template-columns:
    [device] 8fr
    [port] 4fr
    [equipment] 6fr
    [site-total] 3fr
    [breakers] 6fr
    [ct-type] 6fr
    [phase] 4fr
    [ct-polarity] 4fr
    [current] 3fr
    [voltage] 3fr
    [power-factor] 3fr
    [active-power] 4fr;
  grid-template-rows: repeat(1, auto-fill) repeat(auto-fill, 2.5rem);
  align-items: center;
}

.busDeviceTableGen2 {
  font-size: 0.75rem;
  display: grid;
  grid-template-columns:
    [device] 8fr
    [warning] 0.5fr
    [port] 10fr
    [site-total] 3fr
    [breakers] 6fr
    [ct-type] 5fr
    [phase] 4fr
    [ct-polarity] 3fr
    [current] 3fr
    [voltage] 3fr
    [power-factor] 3fr
    [active-power] 4fr;
  grid-template-rows: repeat(1, auto-fill) repeat(auto-fill, 2.5rem);
  align-items: center;
}

.sensorBusTableGen2 {
  font-size: 0.75rem;
  display: grid;
  grid-template-columns:
    [device] 8fr
    [warning] 0.5fr
    [port] 4fr
    [equipment] 6fr
    [site-total] 3fr
    [breakers] 6fr
    [ct-type] 5fr
    [phase] 2fr
    [phase-mode] 2fr
    [ct-polarity] 3fr
    [current] 3fr
    [voltage] 3fr
    [power-factor] 3fr
    [active-power] 4fr;
  grid-template-rows: repeat(1, auto-fill) repeat(auto-fill, 2.5rem);
  align-items: center;
}

.scrollContainer {
  overflow: scroll;
}

.tableWidthContainer {
  min-width: 1280px;
}

.white {
  height: 2.5rem;
  line-height: 2.5rem;
  background-color: var(--color-white);
}

.white > div {
  display: flex;
  align-items: center;
  height: 100%;
}

.gray {
  height: 2.5rem;
  line-height: 2.5rem;
  background-color: var(--shade-05);
}

.gray > div {
  display: flex;
  align-items: center;
  height: 100%;
}

.centered {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.25rem;
}

.sensorReading {
  width: 80%;
  justify-content: right;
  justify-self: right;
  text-align: right;
}

.sensorReadingHeader {
  justify-content: right;
}

.deviceSeparator {
  line-height: 0rem;
  width: 90%;
  border-bottom: 3px solid var(--accent-base);
}

.busDeviceHighlightRow {
  background-color: rgba(234, 235, 236, 0.4);
}

.busDeviceTitle {
  font-size: 0.875rem;
  font-weight: 600;
  height: 100%;
  line-height: 1.5rem;
}

.busDeviceSeparator {
  grid-column: span 11;
  height: 0.0625rem;
  margin-top: 0.5rem;
  background-color: var(--ambient-basePlus75);
}

.busDeviceSeparatorGen2 {
  grid-column: span 13;
  height: 0.0625rem;
  margin-top: 0.5rem;
  background-color: var(--ambient-basePlus75);
}

.eproDeviceNameHeader {
  display: flex;
  align-items: center;
  padding-right: 5rem;
}

.eproDeviceName {
  padding: 0.5rem;
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  height: calc(100% - 2.5rem);
  font-size: 0.8rem;
  line-break: strict;
}

.eproDeviceNameTitle {
  font-weight: bold;
  color: var(--accent-base);
}

.eproDeviceNameSubTitle {
  line-height: 1rem;
  font-size: 0.75rem;
}

.deleteDeviceIcon {
  margin-left: 0.5rem;
  cursor: pointer;
}

.lastReading {
  font-size: 0.75rem;
  padding-bottom: 0.2rem;
}

.tableHeader {
  font-size: 0.625rem;
  padding: 0.5rem 0;
  font-weight: 600;
  display: flex;
  align-items: center;
  height: 1.5rem;
}

.dropdownHeaderPadding {
  padding-left: 0.4rem;
}

.tableCellPadding {
  padding-left: 0.4rem;
}

.bold {
  font-weight: bold;
}

.emptyPort {
  color: var(--ambient-basePlus50);
  cursor: pointer;
}

.breakerClickable {
  color: var(--accent-base);
  font-weight: 600;
  cursor: pointer;
  line-height: normal;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.breakerDropdownSetupMode {
  pointer-events: none;
}

.circuitStatus {
  padding: 0 0.5rem 0 0.5rem;
}

.circuitStatus:first-child {
  padding-left: 0;
}

.circuitStatus:not(:last-child) {
  border-right: solid 1.5px var(--ambient-basePlus75);
}

.selectButtonText {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.warningTooltip {
  line-height: normal;
  position: relative;
  right: -3px;
}
