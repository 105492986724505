.textarea {
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
  font-size: 0.75rem;
  overflow: auto;
  width: 100%;
  height: 10rem;
}
