.content {
  padding: 1.875rem 1.875rem 1.25rem 1.875rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  font-weight: 600;
}

.headerRow {
  font-size: 0.625rem;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0rem;
  border-top: 1px solid var(--ambient-basePlus75);
  border-bottom: 1px solid var(--ambient-basePlus75);
}

.headerCell {
  padding-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
}

.bodyRow {
  font-size: 0.75rem;
  display: flex;
  padding: 1rem 0rem;
}

.cell {
  text-overflow: ellipsis;
  line-break: strict;
  padding-right: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.imageCell {
  display: flex;
  align-items: center;
  padding-right: 1rem;
}

.imagesContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
}

.imageContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.2rem;
}

.image {
  width: 4rem;
  height: 3rem;
  object-fit: cover;
  cursor: pointer;
}

.moreImages {
  display: flex;
  align-items: center;
  color: var(--accent-base);
  cursor: pointer;
}

.input {
  display: none;
}

.disabled {
  color: var(--ambient-basePlus50);
}

.disabledCheckbox {
  cursor: not-allowed;
}

.error {
  width: 100%;
  text-align: center;
  font-size: 0.8rem;
  color: var(--pink-base);
}

.scrollContainer {
  overflow: scroll;
}

.tableWidthContainer {
  min-width: 1280px;
}
