.root {
  display: flex;
  align-items: center;
}

.sensorReadingsText {
  white-space: nowrap;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  margin-left: 0.5rem;
  font-size: 0.75rem;
  color: var(--ambient-basePlus50);
}
