.body {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 90vh;
  margin: 0;
  flex-direction: column;
  color: var(--ambient-base);
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

.mainText {
  font-size: 2rem;
  padding: 0.6rem 0 0.6rem 0.6rem;
}

.subText {
  color: var(--ambient-basePlus50);
  font-size: 1.5rem;
}

.error {
  font-size: 4rem;
  font-weight: bold;
  margin-right: 1.5rem;
}
