.checkboxMargin {
  margin-left: 0.75rem;
}

.verticalAlign {
  display: flex;
  align-items: center;
}

.compactColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.equipmentButtonsContainer {
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
}

.equipmentButton {
  margin-right: 0.5rem;
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
  color: white;
  background-color: var(--accent-base);
  display: flex;
  align-items: center;
  justify-content: center;
}

.equipmentButton:hover {
  cursor: pointer;
  background-color: var(--accent-baseMinus50);
}

.removeEquipmentModalContent {
  padding: 2rem;
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
}

.breakerLiveReadingRow {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.breakerLiveReadingRow:nth-child(even) {
  /* below is var(--ambient-basePlus90) converted to rgba - needed to set opacity easily */
  background-color: rgb(203, 206, 209, 0.4);
}

.liveReadingHeader {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 0.625rem;
  border-top: solid var(--ambient-basePlus75) 1px;
  border-bottom: solid var(--ambient-basePlus75) 1px;
  padding: 0.25rem;
}

.breakerLiveReadingValue {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0 0.5rem 0;
}

.phasorDiagramContainer {
  display: flex;
  margin-top: 1rem;
  align-items: center;
}

.phasorDiagramReadingRow {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.phasorDiagramReadingRow:nth-child(even) {
  background-color: rgb(203, 206, 209, 0.4);
}
