.removeUserButton {
  color: var(--pink-base);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  min-width: 0;
  min-height: 0;
}

.removeUserButton:hover {
  color: var(--pink-base);
  opacity: 0.5;
}

.paddingRight {
  padding-right: 0.25rem;
}

.marginTop {
  margin-top: 1rem;
}

.filterLevelThree {
  position: relative;
}
/* 
.subHeader {
  display: none;
} */

.subHeader {
  padding: -1rem 3rem 0 3rem;
  display: flex;
  justify-content: space-between;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 10px;
  color: #000000;
  position: absolute;
}

/* div[class*='Table_container'] {
  padding-top: 10px;
  border-top: 1px solid var(--ambient-basePlus75);
} */

.filterContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  /* margin-bottom: 1rem; */
}

.paddingTable > div[class*='Table_container'] {
  padding-top: 30px;
}
