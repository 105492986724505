.container {
  display: flex;
  align-items: center;
}

.timeContainer {
  width: 100%;
  max-width: 10rem;
}

.timeInput {
  margin-right: 0.5rem;
}

.customizedTimeInput {
  margin-right: 0.5rem;
}

.invalidText {
  font-size: 0.75rem;
  color: var(--pink-base);
}

.hasError::before,
.hasError::after {
  border-bottom: 1px solid var(--pink-base) !important;
}

.input {
  color: var(--base25);
}

.clockIcon {
  fill: var(--accent-base);
}
