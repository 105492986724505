.listContainer:hover {
  cursor: pointer;
}

.listContainer {
  display: flex;
  justify-content: space-between;
  width: 27.4375rem;
  padding: 1rem;
  padding-left: 3.5625rem;
}

.expandedContainer {
  width: 32rem;
}

.expandedContainerMargin {
  margin: 2rem;
}

.cardListTitle {
  font-size: 0.875rem;
}

.expandedCardTitle {
  font-size: 1.5rem;
}

.tabsContainer {
  margin-top: 1rem;
}

.breakerTabContainer,
.imagesTabContainer {
  margin-top: 2rem;
}

.floatRight {
  float: right;
}

.mainsLayout {
  display: grid;
  grid-template-columns: 1fr 1fr 3fr;
}

.busTab {
  width: 1.875rem;
  border-top: 0.158rem solid var(--turquoise-base);
  font-size: 0.625rem;
  margin-left: 1.4375rem;
}

.noPaddingTop {
  padding-top: 0;
}

.cardListItem {
  width: 32rem;
  height: 3.1875rem;
}

.cardActionsContainer {
  padding-right: 2rem;
}
