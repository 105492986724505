.colorText {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;
}

.textWithTime {
  display: flex;
  flex-direction: column;
}

.timestampText {
  color: #333e48;
  font-size: 0.65rem;
}
