.customProgressBar {
  width: 36px;
  min-height: 0.32rem;
  height: 0.32rem;
  border-radius: 1.3rem;
  overflow: hidden;
  margin-top: 3px;
  background-color: #dadada;
  box-shadow: none !important;
}

.customHighlightBar {
  height: 100%;
  background: var(
    --customBackgroundColor,
    linear-gradient(
      90deg,
      var(--accent-base) 0%,
      var(--accent-base) 80%,
      var(--turquoise-base) 100%
    )
  );
  border-radius: 0.9rem;
  transition: width linear 0.3s;
}
