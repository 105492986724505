.root {
  display: flex;
  /* width: 100vw; */
}

.root > div:last-child {
  flex-grow: 1;
}

.contentPanel {
  display: flex;
  flex-direction: column;
  width: calc(100% - var(--application-menu-width));
}

.content {
  width: calc(100% - var(--application-menu-width));
  margin-left: var(--application-menu-width);
  height: calc(100vh - 4.5rem);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 768px), (max-device-width: 768px) {
  .content {
    width: 100%;
    margin: 0;
  }
}

.ApplicationMenu .ApplicationMenuWrapper {
  background-color: #cbced1;
  display: flex;
  flex-grow: 1;
  height: calc(100vh - 4.5rem);
  overflow-y: auto;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}
.ApplicationMenu div[class*='ApplicationMenu_menuItems'] {
  background-color: #cbced1;
}

.ApplicationMenu
  .ApplicationMenuWrapper
  a
  > div[class*='ApplicationMenuItem_active'] {
  background-color: rgba(0, 160, 174, 0.25);
}

.ApplicationMenu
  .ApplicationMenuWrapper
  a
  > div[class*='ApplicationMenuItem_active']
  > div[class*='ApplicationMenuItem_active'] {
  color: #0597a4;
  opacity: 1;
}

.ApplicationMenu
  .ApplicationMenuWrapper
  a
  > div[class*='ApplicationMenuItem_active']
  > svg {
  color: #0597a4 !important;
  fill: #0597a4 !important;
  opacity: 1;
}

.ApplicationMenu
  .ApplicationMenuWrapper
  a
  > div[class*='ApplicationMenuItem']:hover {
  background-color: rgba(0, 160, 174, 0.25);
}

.ApplicationMenu
  .ApplicationMenuWrapper
  a
  > div[class*='ApplicationMenuItem']
  > svg {
  color: #333f48;
  fill: #333f48;
}

.ApplicationMenu
  .ApplicationMenuWrapper
  a
  > div[class*='ApplicationMenuItem']
  > div[class*='ApplicationMenuItem_title'] {
  color: #000000;
}

/* .ApplicationMenu .ApplicationMenuWrapper a:last-child > div[class*="ApplicationMenuItem"] {
  background-color: rgba(152,158,163, 0.75);
} */

.appMenuIconRoutes {
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.appMenuIconRoutes::-webkit-scrollbar {
  display: none;
}

.InsightsSwitchLink > div {
  background-color: rgba(152, 158, 163, 0.75);
}

/* Navigation bar divider */
.horizontalLine {
  display: flex;
  align-items: center;
  font-size: 0.625rem;
}

.horizontalLine::before,
.horizontalLine::after {
  content: '';
  flex-grow: 1;
  height: 1px;
  background-color: var(--ambient-basePlus50);
}

.dividerText {
  padding: 0.5rem;
  color: var(--ambient-basePlus25);
}
