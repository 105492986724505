.boldText {
  font-weight: bold;
}

.marginTop {
  margin-top: 1rem;
}

.warningIcon {
  width: 6%;
  height: 6%;
  padding-right: 10px;
}
