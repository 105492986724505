.root {
  font-size: 0.75rem;
}

.autoMargin {
  margin: auto 0;
}

.arrow {
  cursor: pointer;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  color: var(--accent-basePlus25);
}

.wrapper {
  border-top: solid 1px var(--ambient-basePlus90);
}

.spaceTitle {
  cursor: pointer;
}

.menuDropdownContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
