.root {
  width: 100%;
  font-size: 0.75rem;
  border-collapse: collapse;
}

.tableTitle {
  font-weight: bold;
  margin-bottom: 1rem;
  display: flex;
}

.cell {
  padding: 0.5rem 0.75rem;
}

.headerCell {
  font-weight: normal;
  padding: 0 0.75rem;
}

.cell:first-of-type {
  padding-right: 0;
  border-right: thin solid var(--ambient-basePlus90);
}

.tableRow:not(:first-of-type) {
  border: thin solid var(--ambient-basePlus90);
}

.forcedOnScheduleRow {
  border: thin solid var(--orange-base);
}

.timeSlotContainer:not(:first-child) {
  margin-top: 0.75rem;
}

.unOccupiedSchedule {
  color: var(--ambient-basePlus50);
}

.forcedOnWarning {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  display: flex;
  column-gap: 1rem;
  color: var(--orange-base);
  background-color: var(--orange-basePlus90);
}

.bold {
  font-weight: bold;
}

.info {
  font-size: 0.75rem;
}

.tooltip {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}
