.listContainer:hover {
  cursor: pointer;
}

.isolateCard {
  padding-bottom: 1rem;
}

.listContainer {
  width: 30rem;
  padding: 1rem;
}

.expandedContainer {
  width: 32rem;
}

.expandedContainerMargin {
  margin: 2rem;
}

.cardListTitle {
  font-size: 0.875rem;
}

.expandedCardTitle {
  font-size: 1.5rem;
}

.tabsContainer {
  margin-top: 1rem;
}

.breakerTabContainer,
.imagesTabContainer {
  margin-top: 2rem;
}

.cardActionsContainer {
  padding-right: 2rem;
}
