.addThermostatButton {
  border-top: 3px solid var(--ambient-basePlus90);
  box-sizing: content-box;
  font-family: inherit;
  padding-right: 1rem;
  padding-left: 1rem;
  color: var(--accent-base);
  font-size: 0.875rem;
  border: 0;
  cursor: pointer;
  height: 2.5rem;
  display: flex;
  align-items: center;
  background: none;
}

.thermostatText {
  margin-left: 0.875rem;
}

.customSelectStyle {
  position: relative;
}

.formBody {
  padding: 1rem 1rem 0.5rem 1rem;
}

.formActionSection {
  box-sizing: border-box;
  background-color: var(--shade-05);
  width: 100%;
  min-height: 3.5rem;
  padding-right: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.selectItem {
  z-index: 999;
}
