.equipmentContainer {
  padding: 0.5rem 2rem 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.75rem;
  font-weight: 600;
  margin-top: 0.2rem;
}

.selectedEquipment {
  background-color: var(--accent-basePlus90);
}

.actionButton,
.icon {
  color: var(--accent-base);
  cursor: pointer;
  font-size: 0.75rem;
}
