.root {
  width: 100%;
}

.statusAndDateInput {
  display: flex;
  align-items: center;
}

.datePickerMarginLeft {
  margin-left: 1rem;
}

.marginVertical {
  margin: 1.875rem 0;
}

.dividerLine {
  border-bottom: 1px solid var(--ambient-basePlus75);
}

.inputLabel {
  width: 12rem;
  display: flex;
  flex-direction: column;
}

.labelDescription {
  font-size: 0.5rem;
  color: var(--medium-base);
}

.inputGroup {
  display: flex;
  padding: 0.75rem 0;
}

.paddingBetweenCards {
  margin-bottom: 1rem;
}
