.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  font-size: 0.875rem;
}

.header {
  line-height: 1.2rem;
  font-weight: bold;
  padding-bottom: 0.625rem;
}

.header > button {
  padding-right: 0.8125rem;
  color: var(--pink-base);
}

.actionHeaderContainer {
  padding-bottom: 2.5rem;
  width: 100%;
}

.accessButton {
  display: flex;
  padding: 0.5625rem 1.0625rem;
  background-color: var(--shade-05);
  color: var(--accent-base);
  line-height: 1.25rem;
  border: none;
}

.accessButton:hover {
  opacity: 0.5;
  cursor: pointer;
}

.divider {
  height: 2.5rem;
}
.accessButton > svg {
  padding-right: 0.8125rem;
}

.resultsContainer {
  height: 24.188em;
  overflow-y: auto;
}

.row {
  display: grid;
  grid-template-columns: 1fr 9fr 1fr;
  padding: 0.5rem 0;
  align-items: center;
  border-bottom: solid 1px var(--ambient-basePlus90);
}

.row:hover {
  background-color: var(--shade-05);
  border-bottom: solid 1px var(--ambient-basePlus90);
}

.accessDropdownContainer {
}

.resourceTypeContainer {
}

.resourceType {
}

.addButtonContainer {
  color: var(--accent-base);
}

.deleteButtonContainer {
  display: none;
  font-weight: normal;
  color: var(--pink-base);
}

.row:hover .deleteButtonContainer {
  display: block;
}

.invisibleButton {
  appearance: none;
  background: transparent;
  border: none;
  top: 0;
  right: 0;
  outline: none;
  z-index: 8000;
  margin: 0;
  padding: 0;
  color: inherit;
  /* color: var(--accent-base); */
}

.invisibleButton:hover {
  opacity: 0.5;
  cursor: pointer;
}

.noResultsContainer {
  padding-left: 2rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  user-select: none;
}
