.modelContent {
  margin-right: 3%;
}

.columnData {
  display: grid;
  grid-template-columns: 8rem 15rem;
  grid-gap: 0.6123rem 2.5rem;
}

.cardContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.versionData {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
}

.firmwareStatus {
  display: flex;
  justify-content: center;
  text-align: left;
  gap: 0.2rem;
  align-items: center;
  border-width: 1px;
  border-color: '#ef740f75';
  border-style: solid;
  background-color: '#ef740f25';
  padding: '0.66rem 0.2rem';
  width: '100%';
  font-size: '0.60rem';
}

.deviceStatus {
  font-size: 0.75rem;
}

.deviceTime {
  color: #333e48;
  font-size: 0.65rem;
}

.labelText {
  color: #333e48;
  font-weight: 700;
}

@media (min-width: 1550px) {
  .modelContent {
    margin-right: 5%;
  }

  .columnData {
    display: grid;
    grid-template-columns: 10rem 20rem;
    grid-gap: 0.6123rem 5rem;
  }
}
