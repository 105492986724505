.root {
  position: relative;
}

.paddingBetweenCards {
  padding-bottom: 3rem;
}

.paddingForTitle {
  padding-bottom: 1rem;
}

.sopFlexList {
  display: flex;
  flex-direction: row;
}

.cardTitle {
  font-weight: bold;
  padding-bottom: 2rem;
}

.bottomOfPage {
  position: absolute;
  bottom: 0;
}

.paddingForTable {
  padding-right: 1.25rem;
}
