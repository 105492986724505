.title {
  display: grid;
  grid-template-columns: auto 200px;
}

.icon,
.remoteAccessDisabled {
  margin-top: 0.35rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.icon {
  cursor: pointer;
}

.updateConfigButtonContainer,
.remoteAccessButtonContainer {
  display: flex;
  align-items: center;
}

.remoteAccessButtonContainer {
  flex-direction: column;
  padding-top: 0.2rem;
}

.loader {
  width: 1rem;
  margin-left: 0.5rem;
}

/* For Org level netwrokgroup page */

.pageWrapper {
  margin: 2rem 2rem 0 2rem;
}

.actionColumn {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.tooltipContent,
.remoteAccessDisabled {
  color: var(--ambient-basePlus50);
}

.remoteAccessUrl {
  font-weight: 600;
  color: #fff;
}

.tooltipContent {
  display: flex;
  flex-direction: column;
  row-gap: 0.5em;
}

.online {
  color: var(--green-base);
}

.heartbeatOnly {
  color: var(--orange-base);
}

.offline {
  color: var(--pink-base);
}

.tooltipSeparator {
  height: 1px;
  background-color: currentColor;
}

.statusDotOutter {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  position: absolute;
  right: -12px;
  bottom: 4px;
  overflow: hidden;
}

.statusDotInner {
  width: 100%;
  height: 100%;
  background-color: currentColor;
}

@media (max-width: 1500px) {
  .wrapFirst {
    word-wrap: anywhere;
  }
}

@media (max-width: 1400px) {
  .wrapSecond {
    word-wrap: anywhere;
  }
}
