.siteDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.testBannerExist {
  padding-top: 0.5rem;
}

.siteContainer {
  border-top: thin solid var(--ambient-basePlus90);
  margin: 1rem 0 1.2rem 0;
  padding-top: 0.5rem;
}

.title {
  font-weight: 600;
  line-height: 1.1875rem;
  font-size: 0.75rem;
}

.tilesContainer {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 23.75rem);
  grid-auto-rows: 26.875rem;
  grid-auto-flow: dense;
  grid-gap: 1.2rem;
}

.sensorReadingsContainer,
.siteDetailsContainer {
  display: flex;
  flex-wrap: wrap;
}

.alignIconAndText {
  display: flex;
  align-items: center;
  padding-right: 1rem;
}

.sensorIcons {
  color: var(--accent-base);
  display: flex;
}

.iconText {
  font-size: 0.75rem;
  padding-left: 0.25rem;
}

.siteDetailsContainer > div:not(:first-child):before,
.sensorReadingsContainer > div:not(:first-child):before,
.temperatureContainer > div:not(:first-child):before {
  content: '|';
  color: var(--ambient-basePlus75);
  padding-right: 1rem;
  padding-left: 0.5rem;
}

.lastConfigUpdateStatus {
  display: flex;
  font-weight: 600;
  font-size: 12px;
}

.loader {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.temperatureContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
}
