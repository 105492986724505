.modalFormContent {
  grid-template-columns: 8.5rem 17rem;
}

.root {
  margin-top: 1rem;
}

.biasFieldContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.biasField,
.delaySelector {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
}

.biasInput {
  width: 3rem;
}

.biasColon {
  margin: 0 0.75rem;
  font-size: 0.75rem;
}

.smallFont {
  font-size: 0.75rem;
  color: var(--ambient-basePlus50);
}

.marginLeft {
  margin-left: 0.5rem;
}

.marginRight {
  margin-right: 0.5rem;
}

.marginBottom {
  margin-bottom: 1rem;
}

.grayText {
  color: var(--ambient-basePlus50);
}

.tooltipRoot {
  min-width: 8rem;
}

.tooltipTitle {
  text-align: left;
  font-weight: bold;
  margin-bottom: 0.5rem;
  font-size: 10.5pt;
}

.tooltipTableWidth {
  width: 30%;
}

.tableText {
  background-color: #333e48;
  color: white;
  font-size: 10pt;
  font-weight: 300;
  text-align: left;
  border-collapse: collapse;
  min-width: 14rem;
}

.tableText thead tr th {
  font-weight: 300;
}

.tableText thead {
  border-bottom: 0.5px solid white !important;
}

.tooltipLineEntry {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: 8rem;
  font-size: 10pt;
}

.tooltipCommentLine {
  font-size: 10pt;
  margin-top: 0.5rem;
}

.selectTimetable {
  width: 100%;
}

.selectTimetableContainer {
  display: flex;
  align-items: center;
}

.luxValueContainer {
  display: flex;
  flex-wrap: nowrap;
  gap: 1.5rem;
}

.luxValue {
  margin-top: 0.3rem;
  font-size: 10pt;
  white-space: nowrap;
  color: var(--ambient-basePlus50);
}
