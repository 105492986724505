.statusAndSettingsContainer {
  display: block;
}

.tstatStatusContainer {
  display: flex;
  padding-bottom: 15px;
  border-bottom: solid 1px var(--ambient-basePlus90);
  width: 100%;
}

.tstatSettingsContainer {
  display: flex;
  padding-top: 15px;
  width: 100%;
}

.leftContainer {
  width: 20%;
}

.middleContainer {
  width: 40%;
}

.rightContainer {
  width: 40%;
}

.thermostatFormContent {
  display: grid;
  grid-template-columns: 10rem 14rem;
  grid-gap: 0.6125rem 0.5rem;
}

.thermostatFormLabel {
  display: flex;
  align-items: center;
  color: var(--ambient-basePlus25);
  font-size: 0.75rem;
}

.thermostatSubTextLabel {
  display: block;
  align-items: center;
  color: var(--ambient-basePlus25);
  font-size: 0.75rem;
}

.signalStrengthLabel {
  align-items: center;
  display: block;
  font-size: 0.75rem;
  color: var(--ambient-basePlus25);
}

.content {
  padding: 1rem !important;
}

.online {
  color: var(--green-base);
}

.offline {
  color: var(--pink-base);
}

.emptyRow {
  padding-top: 10px;
}

.emptyContentRow {
  padding-top: 5px;
}

.paddingLeft {
  padding-left: 10px;
}

.fanRow {
  display: 'inline-flex';
}
