.modal {
  position: absolute;
  display: relative;
  top: -12rem;
  left: 5rem;
  z-index: 100;
  background-color: white;
  line-height: normal;
}

.arrow {
  position: absolute;
  top: 13.25rem;
  left: -1rem;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 1rem solid transparent;
  border-bottom: 1rem solid transparent;
  border-right: 1rem solid white;
  z-index: 101;
}

.title {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.diagramContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.centeredField {
  align-self: center;
}

.grayText {
  font-size: 0.75rem;
  color: var(--ambient-basePlus75);
}
