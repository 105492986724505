.content {
  padding-top: 2rem;
  text-align: center;
}

.pinkText {
  color: var(--pink-base);
  font-size: 0.875rem;
  padding-top: 1rem;
}

.bold {
  font-weight: bold;
}
