.numericInput {
  display: flex;
  font-size: 0.75rem;
  align-items: center;
  width: 6rem;
}

.numericLongInput {
  display: flex;
  font-size: 0.75rem;
  width: auto;
  align-items: flex-start;
  flex-direction: column;
}

.numericLongInputUnit {
  line-height: 2rem;
}

.withExtraUnit {
  flex-direction: row;
}
