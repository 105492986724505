.root {
  font-size: 0.875rem;
}

.modalTitle {
  position: relative;
  margin: 28px 75px 16px;
}

.modalTitleIcon {
  position: absolute;
  left: -45px;
  top: 2px;
}

.modalContent {
  padding: 0 75px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.count {
  margin-top: 1em;
  font-weight: 700;
}

.count svg {
  transform: translate(0, 2px);
}

.error {
  color: var(--pink-base);
}

.warning {
  color: var(--orange-base);
}

.instruction {
  margin-bottom: 1em;
}

.predefined {
  color: var(--medium-base);
}

.inputField {
  width: 70%;
}

.inputField::placeholder {
  color: var(--ambient-basePlus75);
}

textarea {
  margin-top: 1.5em;
  border: thin solid var(--ambient-basePlus75);
  height: 4rem;
  resize: none;
}

textarea:focus {
  outline: none;
}

textarea:focus:not(.hasError):not(:disabled),
textarea:hover:not(.hasError):not(:disabled) {
  border: thin solid var(--accent-base);
}

.textBox::placeholder {
  color: var(--ambient-basePlus75);
  font-style: italic;
  margin-bottom: 0.2rem;
}

.successMessage {
  padding-top: 30px;
  text-align: center;
}

.successTitle {
  margin: 2rem 0;
}

.successContent {
  padding: 0 3.75rem 0.25rem;
  height: 3.15rem;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
