.root {
  background-color: var(--shade-05);
  position: relative;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
}

.handling {
  display: grid;
  grid-template-columns: 3fr 2fr 2fr 3fr 5fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  align-items: flex-start;
}

.actions {
  display: flex;
  justify-content: space-between;
}

.actionsEditable {
  margin-top: 1.875rem;
}

.actionsPositionFixed {
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
}

.low::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-left: 3px solid var(--notification-blue);
}

.medium::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-left: 3px solid var(--notification-yellow);
}

.high::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-left: 3px solid var(--notification-orange);
}

.critical::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-left: 3px solid var(--notification-red);
}

.deleteIcon {
  margin-right: 0.5rem;
  color: var(--pink-base);
}

.deleteText {
  color: var(--pink-base);
}

.trashIcon {
  cursor: pointer;
}

.inputField {
  width: 4rem;
}

.selectAll {
  color: var(--accent-base);
  font-size: 12px;
  user-select: none;
  cursor: pointer;
  margin: 0.875rem auto 0.8rem 1rem;
}

.multiSelect {
  color: grey;
}

.multiSelect > div[class*='MultiSelectItem_check'] {
  color: grey;
}

div[class*='MultiSelect_portalDropdownItemsList'] {
  overflow: hidden !important;
}

.errorContainer {
  font-size: 0.75rem;
  color: var(--pink-base);
}
