.checkboxWrapper {
  display: flex;
  justify-content: space-around;
  flex: 1;
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
}

.error {
  margin: 0;
  text-align: center;
}

.errorContainer {
  font-size: 0.75rem;
  line-height: 1.5rem;
  color: red;
}
