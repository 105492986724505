.updateFirmwareWarning {
  background-color: #ef740f25;
  padding: 0.66rem 0.2rem;
  width: 100%;
  font-size: 0.6rem;
  gap: 0.2rem;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-color: #ef740f75;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.inputContainer {
  flex: 1;
}

.inputContainer > div {
  display: none;
}
