.container {
  text-align: left;
  padding-bottom: 1rem;
  padding-right: 1rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.title {
  font-size: 0.875rem;
}

.headerRightAlign {
  display: flex;
  align-items: center;
}

.zoomResetContainer {
  margin-left: 0.5rem;
}
