.root {
  font-size: 0.75rem;
  font-weight: 400;
  margin: 2rem 4rem;
  font-family: 'Open Sans', sans-serif;
}

.relayButton {
  color: #00a1af;
  background-color: white;
  border: 1px solid #00a1af;
  font-weight: 600;
  padding: 0.5rem;
}

.hideUnmappedRelays {
  background-color: #e5f5f6;
}

.outputStatus {
  padding-left: 2rem;
}

.actuatorTable {
  padding-top: 0.5rem;
  padding-left: 3rem;
  width: 100%;
  font-size: 5rem;
}

.buttonText {
  padding-left: 0.5rem;
  font-size: 0.85rem;
  color: #ffffff;
}

.runTest {
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
}

.addRelay {
  display: flex;
  flex-direction: row;
  border-top: 0.1rem solid #dadada;
  gap: 2rem;
}

.addRelayButton {
  height: fit-content;
  width: 8rem;
  margin-top: 1rem;
}

.controlBoardTitle {
  padding-top: 0.5rem;
}

.equipmentCount {
  font-size: 0.85rem;
  padding-right: 2rem;
  font-weight: bold;
}

.startSequence {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  padding-bottom: 1rem;
  border-bottom: 0.1rem solid #dadada;
}

.number {
  font-size: 1.5rem;
  font-weight: 600;
  padding-right: 1rem;
}

.line {
  border-top: 0.1rem solid #000000;
  padding: 0 5rem;
  position: relative;
  top: 1.4rem;
}

.startSequenceButton {
  padding: 0.7rem;
  white-space: break-spaces;
}

.iconColor {
  color: #ffffff;
}

.cardContent {
  padding-bottom: 1rem;
}

.icon {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.text {
  color: #000000;
  font-weight: 600;
  font-size: 0.85rem;
}

.unmappedRelayText {
  padding: 0.1rem 0.7rem;
  font-size: 0.85rem;
}
