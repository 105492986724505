.dropdownContainer {
  height: 100%;
  display: flex;
  align-items: center;
}

.listTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.groupHeader {
  padding-right: 1rem;
}

.sopAttachmentInfoHeaderCell {
  position: relative;
}

.sopAttachmentInfoHeader {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 0 0.5rem;
  position: absolute;
  top: -0.5rem;
  left: 0;
  width: 100%;
}

.sopCountHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  left: 0;
  width: 100%;
}

.countHr {
  border-bottom: 2px solid var(--accent-base);
  width: 95%;
}

.countSubtitlesWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.countSubtitles {
  flex: 1;
  text-align: center;
}

.sopAttachmentInfo {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 0px 0.5rem;
}

.title {
  font-size: 0.875rem;
  font-weight: 600;
}

.description {
  margin-top: 0.5rem;
  font-size: 0.75rem;
}

.componentType {
  font-weight: bold;
  margin-bottom: 0.25rem;
}

.sopAttachmentInfoContainer {
  padding-bottom: 0.35rem;
  display: grid;
  grid-template-columns: 1fr 1.15fr 1fr;
}

.sopAttachmentInfoContainer:not(:first-of-type) {
  border-top: thin solid var(--ambient-basePlus75);
  padding-top: 0.35rem;
}

.disabled {
  color: var(--medium-base);
}
