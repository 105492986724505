.modal {
  position: absolute;
  top: -7.8125rem;
  left: 8rem;
  z-index: 100;
  background-color: white;
  line-height: normal;
  min-width: 480px;
}

.arrow {
  position: absolute;
  top: 9rem;
  left: -1rem;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 1rem solid transparent;
  border-bottom: 1rem solid transparent;
  border-right: 1rem solid white;
}

.card {
  margin-bottom: 0.125rem;
}

.title {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.diagramContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.indexContainer {
  border-top: solid var(--ambient-basePlus75) 1px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
}

.indexTitle {
  font-weight: 600;
}

.indexStrings {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.25rem;
  margin-right: 1.25rem;
}

.indexString {
  padding: 0 0.5rem;
  white-space: nowrap;
}

.indexString:not(:last-child) {
  border-right: solid var(--ambient-basePlus75) 1px;
}
