.titleBlock {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.6rem;
}

.titleIcon {
  margin-top: 1px;
}

.title {
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.3rem;
}

.subtitle {
  color: var(--ambient-base);
  font-size: 0.8rem;
  font-weight: 600;
}

.inspectionResultBlock {
  margin-top: 0.8rem;
}

.hrContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 1rem;
}

.hr {
  border-bottom: 1px solid var(--shade-25);
  width: 100%;
}

.fieldsContainer {
  display: grid;
  gap: 0px 0.5rem;
  grid-template-columns: repeat(12, 1fr);
  align-items: flex-start;
}

.fieldTitle {
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 1.5;
  margin: 0.4rem 0;
}

.clickableFieldTitle {
  color: var(--accent-base);
  font-weight: bold;
  cursor: pointer;
}

.fieldValue {
  font-size: 0.8rem;
  line-height: 1.5;
  margin: 0.4rem 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.fieldDescription {
  font-style: italic;
  font-size: 0.65rem;
  color: var(--ambient-base);
  align-self: flex-end;
  position: relative;
}

.good,
.online,
.active {
  font-weight: 700;
  color: #01d33d;
}

.warning {
  font-weight: 700;
  color: var(--orange-base);
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.error,
.offline,
.inactive {
  font-weight: 700;
  color: var(--pink-base);
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.detailTable {
  margin-top: 1rem;
}

.tableFieldValue {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.centeredTableFieldValue {
  justify-content: center;
}

.noTableData {
  font-size: 0.8rem;
}

.tooltip {
  display: flex;
}

.tooltipText {
  font-weight: normal;
  font-style: normal;
  font-size: 0.75rem;
  white-space: pre-line;
}

.longValue {
  word-wrap: anywhere;
}
