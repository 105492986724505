.listContainer:hover {
  cursor: pointer;
}

.listContainer {
  width: 30rem;
  padding: 1rem;
}

.expandedContainer {
  width: 32rem;
}

.expandedContainerMargin {
  margin: 2rem;
}

.cardListTitle {
  display: flex;
  justify-content: space-between;
  padding-right: 1.6rem;
  font-size: 0.875rem;
}

.expandedCardTitle {
  font-size: 1.5rem;
}

.tabsContainer {
  margin-top: 1rem;
}

.breakerTabContainer,
.imagesTabContainer {
  margin-top: 2rem;
}

.floatRight {
  float: right;
}

.mainsLayout {
  display: grid;
  grid-template-columns: 1fr 1fr 3fr;
}

.updateConfigText {
  padding: 1rem 4rem;
  text-align: center;
}

.cardActionsContainer {
  padding-right: 2rem;
}
