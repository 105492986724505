.disabled {
  color: var(--medium-base);
}

.description {
  padding-left: 20px;
  font-size: 0.35rem;
  color: var(--ambient-basePlus50);
}

.inputFieldContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inputField {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
}

.input {
  width: 3rem;
}

.inputSeparator {
  margin: 0 0.75rem;
  font-size: 0.75rem;
}

.smallFont {
  font-size: 0.75rem;
  color: var(--ambient-basePlus50);
}

.prompt {
  padding: 2rem;
}

.promptTitle {
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 1.2rem;
}

.promptContent {
  font-size: 0.85rem;
  margin-bottom: 0.85rem;
}

.countLoader {
  display: inline-block;
}

.equipmentTypeMultiSelectContainer {
  margin-bottom: 2rem;
}
