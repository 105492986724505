.tooltipRoot {
  display: flex;
  flex-direction: column;
  padding: 0.8rem;
}

.tooltipTitle {
  font-weight: bold;
}

.contentRow {
  display: flex;
  font-size: 0.75rem;
}

.row {
  display: flex;
  justify-content: space-between;
  padding-top: 0.6rem;
  font-size: 0.75rem;
}

.utcMarginLeft {
  margin-left: 0.25rem;
}

.timeAndLocationIcon {
  margin-right: 0.25rem;
  color: var(--accent-base);
}
