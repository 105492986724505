.dragFile {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  margin: 0;
  font-size: 0.9rem;
  color: var(--ambient-base);
  border: 2px dashed var(--ambient-basePlus75);
}
