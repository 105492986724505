.bold {
  font-weight: bold;
}

.redText {
  color: var(--pink-base);
}

.orangeText {
  color: var(--orange-base);
}

.tooltip:hover {
  visibility: visible;
}

.tooltip {
  text-align: end;
  font-size: 0.8rem;
  font-weight: 600;
}

.tooltipText {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 0.8rem;
}

.tooltipLineEntry {
  align-items: center;
  margin: 0.3rem;
  font-weight: 400;
  font-size: 0.75rem;
}

.link {
  font-size: 0.8rem;
  color: #3fb8c2;
}

.Title {
  font-size: 0.875rem;
  font-weight: 400;
  color: #dadada;
  width: 150px;
}

.label {
  box-sizing: border-box;
  background: rgba(217, 217, 217, 0.1);
  border: 1px solid #d8d8d8;
  font-size: 0.75rem;
  padding: 0.3rem;
  width: 82px;
  margin-top: 0.3rem;
  margin-right: 0.3rem;
}

.subTitle {
  font-size: 0.75rem;
  color: #ffffff;
  font-weight: 400;
  font-style: italic;
  margin-bottom: 0.2rem;
}

.lastConfigUpdateContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  font-size: 0.85rem;
}

.lastConfigUpdate {
  display: flex;
  flex-direction: row;
  gap: 0 0.7rem;
  margin-bottom: 0.75rem;
  border-bottom: 1.5px solid #75797c;
  padding-bottom: 0.75rem;
}

.linkText {
  color: #ffffff;
  width: 100%;
  letter-spacing: -0.3px;
  font-size: 0.75rem;
}

.timeStamp {
  font-size: small;
}

.loader {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.noNetworkGroupText {
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: 600;
  border-bottom: 1.5px solid #75797c;
}
