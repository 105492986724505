.root {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-gap: 1rem;
  padding: 1rem;
  background-color: var(--white-50);
}

.root + .root {
  margin-top: 1rem;
}

.timeSelect {
  display: flex;
  align-items: center;
}

.deleteIcon {
  margin-right: 0.5rem;
  color: var(--pink-base);
  cursor: pointer;
}

.controls {
  margin-bottom: 0.5rem;
}

.timeSelectSeparator {
  margin-left: 1rem;
  margin-right: 1rem;
}

.timeRanges {
  padding-bottom: 0.5rem;
}

.deleteButton,
.deleteButton:hover {
  border-color: var(--pink-base);
  color: var(--pink-base);
}

.addIcon {
  color: var(--accent-base);
  margin-right: 0.5rem;
}
