.root {
  display: flex;
}

.label {
  flex: 1;
  justify-content: flex-end;
  margin-right: 1.875rem;
}

.condition {
  flex: 2;
}

.condition,
.label {
  display: flex;
}

.label + .condition {
  margin-left: 1.875rem;
}

.conditionDescription {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  font-size: 0.75rem;
  margin-bottom: 0;
}

.labelAlignedTop {
  align-items: flex-start;
}

.conditionAlignedCenter {
  align-items: center;
}

.root + .root {
  margin-top: 1rem;
}

.conditionTitle {
  font-size: 0.875rem;
  line-height: 1.5em;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0.5rem;
}
