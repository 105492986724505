.formErrorText {
  font-size: 0.75rem;
  color: var(--pink-base);
}

.inputMessage {
  font-size: 0.75rem;
  color: var(--ambient-basePlus50);
  display: flex;
}

.modalContent > div {
  min-width: 18rem;
}

.datePicker + div {
  position: absolute;
  top: -18rem;
  left: -8rem;
}
