.confirmationContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  white-space: nowrap;
}

.checkIcon {
  color: var(--accent-base);
}

.cancelIcon {
  position: relative;
  bottom: 0.1rem;
  border: solid 1px var(--pink-base);
  color: var(--pink-base);
  text-align: center;
  font-size: 0.7rem;
  border-radius: 2rem;
  padding: 0rem 0.3rem;
}

.cancelIconText {
  position: relative;
  bottom: 0.05rem;
}

.confirmIcon {
  margin: 0 0.75rem;
}

.confirmIcon:hover,
.cancelIcon:hover {
  cursor: pointer;
}

.date {
  white-space: nowrap;
}

.updateFirmwareButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.updateFirmwareIcon {
  margin-left: 0.2rem;
  display: flex;
  align-items: center;
}

.tableContainer {
  height: 18.75rem;
  width: 48rem;
  overflow-y: auto;
}

.grayLineDivider {
  border: solid;
  border-color: var(--ambient-basePlus90);
  border-width: 0.1rem;
  position: relative;
  bottom: 0.2rem;
  z-index: -1;
}
