.root {
  padding: 1rem 0;
  display: flex;
  justify-content: space-around;
}

.wizardCard {
  width: 275px;
  height: 205px;
}
