.root {
  width: 4.375rem;
  height: 18rem;
  box-shadow: var(--shadow20);
}

.addButton,
.button {
  width: 100%;
  border: none;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  outline: none;
}

.addButton {
  height: 2.5rem;
  background-color: var(--accent-base);
  color: var(--color-white);
  border-bottom: 0.1875rem solid var(--turquoise-base);
}

.button {
  height: 3.875rem;
  padding: 0 0.375rem;
  font-size: 0.5rem;
  background-color: var(--color-white);
  color: var(--accent-base);
  border-bottom: 0.0625rem solid var(--ambient-basePlus90);
}

.button:focus {
  background-color: var(--accent-baseMinus25);
  color: var(--color-white);
}

.button:hover {
  cursor: pointer;
}

.button svg {
  display: block;
  padding-bottom: 0.25rem;
}
