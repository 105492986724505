.root {
  flex: 1;
}

.range {
  position: relative;
  height: 1rem;
}

.label {
  font-size: 0.875rem;
}

.labelContainer {
  margin-bottom: 0.5rem;
  text-align: center;
}

.range__controls {
  display: flex;
  justify-content: space-between;
}

.range__active,
.range__track {
  position: absolute;
  height: 0.5rem;
  top: 0.5rem;
  border-radius: 0.625rem;
}

.range__active {
  position: absolute;
  background-color: var(--accent-base);
  z-index: 1;
  top: 0.25rem;
}

.range__track {
  position: absolute;
  background-color: var(--ambient-basePlus75);
  z-index: 0;
  left: 0;
  right: 0;
  top: 0.25rem;
}

.range__input {
  width: 100%;
  height: 1rem;
  outline: none;
  position: absolute;
  background: transparent;
  box-sizing: border-box;
  appearance: none;
  pointer-events: none;
}

input[type='range']:active,
input[type='range']:focus {
  outline: none;
}

input[type='range']::-moz-focus-outer {
  border: 0;
}

input[type='range']::-ms-track {
  box-shadow: none;
  border: none;
  background: transparent;
  -webkit-appearance: none;
}

input[type='range']::-webkit-slider-runnable-track {
  box-shadow: none;
  border: none;
  background: transparent;
  -webkit-appearance: none;
}

input[type='range']::-moz-range-track {
  display: none;
}

input[type='range']::-moz-range-thumb {
  z-index: 2;
  background-color: var(--accent-base);
  border-radius: 100%;
  height: 1rem;
  width: 1rem;
  position: relative;
  box-sizing: border-box;
  appearance: none;
  cursor: pointer;
  pointer-events: all;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  z-index: 2;
  background-color: var(--accent-base);
  border-radius: 100%;
  height: 1rem;
  width: 1rem;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  pointer-events: all;
}
