.root {
  margin-bottom: 3rem;
}

.boldText {
  font-weight: 600;
  display: block;
}

.controls {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.headerGrid {
  grid-template-columns: 1.5rem repeat(2, [col-start] 1fr) 1.5rem repeat(
      10,
      [col-start] 1fr
    ) !important;
}

.headerCellAdjust {
  padding-left: 1.5rem;
}

.countHeaderCellAdjust {
  padding-left: 0.75rem;
}

.rowTitle {
  font-size: 0.875rem;
  font-weight: 600;
  word-break: break-word;
}

.pointerCursor {
  cursor: pointer;
}

.timeChip {
  margin-left: 0.3125rem;
}

.singleRow {
  display: grid;
  grid-template-columns: 1.5rem repeat(12, [col-start] 1fr);
  grid-gap: 0.5rem;
  padding: 1rem 0;
  border-bottom: 1px solid var(--ambient-basePlus90);
}

.firstRow {
  border-top: 1px solid var(--ambient-basePlus90);
}

.recordInfoTooltip {
  padding-top: 1rem;
  padding-left: 2rem;
}

.siteLevelReferenceCell {
  display: grid;
  grid-template-columns: repeat(4, [col-start] 1fr);
  grid-gap: 0px 0.5rem;
}

.siteLevelReferenceInnerCell {
  display: grid;
  grid-template-columns: repeat(3, [col-start] 1fr);
  grid-gap: 0px 0.5rem;
}

.autoLockIcon {
  fill: var(--pink-base);
}

.autolockTooltip {
  padding: 0.5rem;
}

.autolockTooltipTitle {
  font-weight: bold;
  text-align: left;
  font-size: 0.9rem;
  margin-bottom: 0.7rem;
}

.autolockTooltipContent {
  width: 11rem;
  white-space: break-spaces;
  text-align: left;
}

/* TimeTable Visualizatio Graph */

.schedulerIcon {
  color: var(--accent-base);
  cursor: pointer;
  margin: 1rem 0 0 1rem;
}

.iconsContainer {
  display: flex;
  justify-content: space-between;
}

.rowDropdownContent {
  background-color: rgba(234, 235, 236, 0.4);
  border-color: inherit;
}

.timetableContainer {
  width: 100%;
  bottom: 0;
}

.timeTableWidth {
  width: 100rem;
}

.specialColumnSpace {
  padding-bottom: 0.5rem;
}
