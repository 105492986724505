.displayFlex {
  display: flex;
  word-break: break-all;
}
.uploadedBillwarning {
  padding-left: 0.5rem;
}
.subText {
  font-size: 0.563rem;
}
.billName {
  padding-left: 0.4rem;
  display: inline-block;
}
.timPeriod {
  display: block;
}
.filesIcon {
  display: inline-block;
}
.billNameSection {
  display: flex;
}
.errorText {
  color: var(--pink-base);
}
.uploadBills {
  display: inline-block;
  margin: 1rem 0;
}
.customButton {
  padding: 0.3rem 0.8rem 0.3rem 0.2rem;
}
.edmHealth {
  display: inline-block;
}

.edmHealthBar {
  padding: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1.25rem;
  gap: 0.4rem;
}

.edmHealthWarning {
  display: inline-block;
  padding: 0 0.5rem;
}

.progressBar {
  position: relative;
  top: 0.28rem;
  margin-right: 0.5rem;
}

.warningText {
  color: var(--orange-baseMinus10);
}
