.conditionalsWrapper {
  margin: 0 auto;
}

.notifyAfterItem {
  max-width: initial;
  display: flex;
  flex-direction: row;
}

.inlineInput {
  max-width: 3.5rem;
}

.hhmmMark {
  margin: 0 0.5rem;
}
