.modalTitle {
  margin: 2rem 4rem 1rem 4rem;
}

.modalContent {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  padding: 2rem 2rem 0 2rem;
  color: #333e48;
  font-size: 0.875rem;
  width: 45rem;
}

.carouselBody {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 1rem;
  text-align: center;
}

.siteTitle {
  font-weight: 600;
  white-space: nowrap;
}

.siteAddress {
  white-space: nowrap;
}

.headerInfo {
  padding: 0 4rem 1rem 4rem;
}

.chevronIcon {
  color: var(--accent-base);
  cursor: pointer;
  margin: 0 0.3rem;
  margin: auto 0.3rem;
}

.leftIcon {
  text-align: left;
}

.rightIcon {
  text-align: right;
}

.uploadedFilesList {
  margin: 0 2rem !important;
}

.uploadedFileItem {
  display: grid;
  grid-template-columns: repeat(2, 8rem) [date] 3fr;
}

.datePicker {
  margin: auto;
}

.disabled {
  color: var(--ambient-basePlus50);
  pointer-events: none;
}

.errorSection {
  text-align: center;
  margin-top: 1rem;
}

.displayFlex {
  display: inline-flex;
}

.uploadedBillwarning {
  padding-left: 0.5rem;
}

.warningText {
  color: var(--orange-baseMinus10);
}

.errorText {
  color: var(--pink-base);
}

.uploadInfo {
  display: inline-block;
  vertical-align: top;
}
