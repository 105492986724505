.cardContainer {
  margin: 1rem 0rem;
}

.cardContent {
  padding: 1.875rem 1.875rem 0.25rem 1.875rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 2rem;
}

.headerRightAlign {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.title {
  font-weight: bold;
  font-size: 0.875rem;
}

.zoomResetContainer {
  margin-left: 0.5rem;
}

.chartResolutionFilterContainer {
  margin-right: 0.5rem;
}
