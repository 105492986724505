.subSection {
  width: 50%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 0.75rem;
}

.subSectionField {
  padding-top: 0.5rem;
  font-size: 0.75rem;
}
