.cardTopMargin {
  margin-top: 1rem;
}

.cardHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  border-bottom: thin solid var(--ambient-basePlus90);
}

.boldText {
  font-weight: bold;
}

.grayText {
  color: var(--ambient-basePlus75);
}

.rightAlign {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.cardContent {
  margin-top: 2rem;
}

.controlsInfoCardContent {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1.75fr 1fr;
  grid-column-gap: 4rem;
}

.recordInfoTooltip {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modalFormContent {
  grid-template-columns: 8.5rem 20rem;
}

.valueContainer {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
}

.controlCardNotesContainer {
  padding-top: 0.5rem;
}

.column {
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 0.75rem;
}

.dividerLine {
  border-bottom: 1px solid var(--ambient-basePlus75);
  margin: 0.5rem 0;
}

.value {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  line-height: 1.5;
  font-size: 0.75rem;
}

.streamTimestampText {
  color: var(--medium-base);
  font-size: 0.675rem;
  font-style: italic;
  margin-left: 0.25rem;
}

.lastConfigUpdateContainer {
  margin-top: 1rem;
  border-top: solid thin var(--ambient-basePlus75);
  padding-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 0 1rem;
  font-size: 0.75rem;
}

.lastConfigUpdate {
  display: flex;
  justify-content: flex-end;
  gap: 0 1rem;
}

.edmSchedule {
  color: var(--ambient-basePlus50);
  font-size: 0.675rem;
}

.edmTable {
  margin-top: 3rem;
}

.deletePrompt {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  text-align: center;
}

.pinkText {
  padding-top: 1rem;
  color: var(--pink-base);
  font-size: 0.875rem;
}

.RunConfigTestWarning {
  padding: 1.5rem 3rem;
}
