input::placeholder {
  color: var(--ambient-basePlus50);
}

.modalFormContent {
  grid-template-columns: 8.5rem 18rem;
  padding-top: 1rem;
}

.dividerLine {
  border-bottom: 1px solid var(--ambient-basePlus75);
  margin: 1.25rem 0 0.5rem 0;
}

.row {
  display: flex;
  width: 100%;
}

.backgroundShade {
  background-color: rgba(234, 235, 236, 0.5);
  border: 1.25px dashed #979797;
  margin-top: 1rem;
  padding: 0 0.5rem;
  width: 100%;
}

.rowEnd {
  justify-content: flex-end;
}

.rowSpaceBetween {
  justify-content: space-between;
}

.trashButton {
  cursor: pointer;
  color: var(--pink-base);
  float: right;
}

.inputFieldContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inputSeparator {
  margin: 0 0.75rem;
  font-size: 0.75rem;
}

.inputField {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
}

.setPointLabel {
  width: 100%;
}

.setPointRecommendation {
  grid-column: 1 / span 2;
  font-size: 0.75rem;
  font-weight: 600;
  background-color: var(--accent-basePlus90);
  padding: 10px;
}

.setPointRecommendation > .changeSetpoint {
  float: right;
  margin-right: 3rem;
}

.changeSetpoint span {
  display: inline-block;
  font-weight: 300;
  min-width: 4.7rem;
}

.changeSetpoint span:last-child {
  margin-left: 4rem;
}

.recommendationTemp {
  font-weight: 600;
  font-size: 0.8rem;
  display: inline;
}

.dismissRecommendation {
  float: right;
  margin-top: -5px;
}

.minContentMissingLabel {
  font-size: 0.75rem;
  color: var(--pink-base);
}

.toggleRowTitle {
  padding-top: 1rem;
  font-size: 0.85rem;
  justify-content: space-between;
}

.toggleRowMinimizedContent {
  padding-top: 0.75rem;
  font-size: 0.75rem;
}

.labelText,
.boldText {
  font-weight: bold;
}

.redText {
  color: var(--pink-base);
}

.localAdjustmentContainer {
  display: flex;
  align-items: center;
}

.localAdjustmentTooltip {
  margin-left: -1rem;
  margin-top: 0.5rem;
}

.inputWidth {
  width: 12rem;
}

.localAdjustmentOn {
  color: var(--accent-base);
}

.localAdjustmentOff {
  color: var(--ambient-base);
}

.arrowIcon {
  color: var(--accent-base);
  margin-right: 0.5rem;
  cursor: pointer;
}

.tooltip {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.tooltipText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.875rem;
}

.tooltipLineEntry {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: 8rem;
}

.selectEquipmentType {
  overflow-x: hidden;
}

.marginBottom {
  margin-bottom: 1rem;
}

.remove {
  display: none;
}
