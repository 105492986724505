.cardContainer {
  margin-bottom: 1rem;
}

.cardGrid {
  display: grid;
  grid-template-columns: 1fr 4fr;
}

.cardTitle {
  padding-top: 0.125rem;
  margin-left: 0.9375rem;
}

.orgTitleText {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
}

.imagesContainer {
  margin-left: 1rem;
  margin-top: 2rem;
}
