.dynamic {
  display: grid;
  /* overflow-x: auto; */
}

.container > :not(:first-child) {
  margin-top: 1.25rem;
}

.containerPadding {
  padding: 1.25rem 3rem;
}
