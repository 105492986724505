.container {
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  gap: 0 0.5rem;
}

.content {
  min-width: 20rem;
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 0.125rem 0;
}

.invisibleButton {
  appearance: none;
  background: transparent;
  border: none;
  top: 0;
  right: 0;
  outline: none;
  z-index: 8000;
  margin: 0;
  padding: 0;
  color: inherit;
}

.invisibleButton:hover {
  opacity: 0.5;
  cursor: pointer;
}

.trashButton {
  color: var(--pink-base);
}

.timeInput {
  margin-right: 1rem;
}

.timeInput {
  padding: 0.5rem 0;
}

.formNotifier {
  display: inline-block;
  padding-top: 1rem;
  white-space: normal;
}

.caption {
  font-weight: bold;
  padding: 0.625rem;
}

.cellContainer {
  display: flex;
  flex-direction: column;
}

.horizontalDivider {
  border-top: 1px solid var(--color-greySeparator);
}

.hidden {
  visibility: hidden;
}
.rowCompress {
  max-height: fit-content;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: auto;
  gap: 0.125rem 0;
  display: grid;
  min-width: 20rem;
  transition: all 1s ease-in-out;
}

.remove {
  border: 0;
  padding: 0;
  overflow: hidden;
  max-height: 0;
  transition: all 0.2s ease-out;
}

.offsetLabel {
  padding: 0.75rem;
  font-weight: normal;
}
.addRemoveTransition {
  padding: 10px;
  max-height: fit-content;
  transition: all 0.2s ease-out;
}

.offsetLabel {
  padding: 0.7rem;
  font-weight: normal;
}
