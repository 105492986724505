.root {
  margin: 2.5rem 2rem;
  padding-bottom: 10rem;
}

.panelEditorContainer {
  display: grid;
  grid-template-columns: 5rem 43rem 32rem;
}

.flexContainer {
  position: relative;
  display: flex;
  justify-content: center;
}

.tabsContainer {
  margin-bottom: 1rem;
}

.editorController {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2rem;
  height: 4.6rem;
}

.radioGroupContainer {
  display: flex;
  align-items: center;
}

.configContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: auto;
  max-width: 13rem;
}

.configContainer button {
  width: 13rem;
}

.configDetails {
  margin-top: 0.1rem;
  font-size: 0.57rem;
  color: grey;
  word-wrap: break-word;
  overflow: anywhere;
}

.configDetails span {
  display: block;
  margin-top: 0.3rem;
}

.energyProGrouping {
  padding-bottom: 1rem;
}

.energyProSelector {
  position: absolute;
  top: 1rem;
  right: 6rem;
}

.connectPortContainer {
  position: absolute;
  left: 0;
  width: 8rem;
  font-size: 0.75rem;
  padding: 1rem;
  box-shadow: var(--shadow10);
  display: flex;
  flex-direction: column;
  background-color: var(--blue-basePlus75);
}

.cancelButton {
  padding-top: 0.5rem;
  color: var(--pink-base);
  cursor: pointer;
  margin-left: auto;
}

.bold {
  font-weight: bold;
}

.dpSettingsCard {
  margin-bottom: 1rem;
}

.dpTableAddDeviceTool {
  display: flex;
  width: 40rem;
  margin-bottom: 1rem;
}

.addButton {
  border: none;
  width: 3rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  color: white;
  background-color: var(--accent-base);
}

.addArrow {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--turquoise-base);
  color: white;
}

.dpTableAddDeviceButton {
  width: 4.5rem;
  height: 4rem;
  border-right: 0.0625rem solid var(--ambient-basePlus90);
}

.dpTableAddDeviceButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: var(--shadow10);
}

.liveReadingTable {
  padding: 0 1rem 3rem 1rem;
  overflow: scroll;
}
