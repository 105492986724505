.root {
  max-width: 55rem;
  margin: 0 auto;
}

.container {
  /* background-color: var(--white-50); */
  padding: 1.875rem;
  margin-top: 0.75rem;
  position: relative;
}

.centeredContainer {
  padding: 1.875rem;
  margin-top: 0.75rem;
  text-align: center;
}

.checkboxContainer {
  padding: 0 28%;
  display: flex;
  align-items: center;
}
.checkboxLabel {
  font-size: 0.75rem;
}
.checkbox {
  font-size: 0.75rem;
  text-align: right;
  margin-right: 1rem;
}
