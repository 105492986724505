.form {
  display: grid;
  grid-template-columns: 50% 50%;
}

.inputGroup {
  display: grid;
  grid-template-columns: 0.2fr 0.5fr;
  padding: 0.75rem 0;
}

.inputGroupPhone {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.inputGroupPhone > div {
  width: 100px;
}
.inputGroupPhone .countryCode {
  min-width: auto;
  width: 52px;
}

.inputGroupPhone .inputContact {
  width: 66%;
}

.phoneNumberInput {
  margin-left: 1rem;
  width: 63%;
  min-width: auto;
}

.phoneInputWrapper {
  display: flex;
  position: relative;
}

.phoneInputWrapper > input + div {
  position: absolute;
  bottom: -21px;
}
