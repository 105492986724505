.root {
  margin: 1rem 0;
}

.content {
  padding: 1.875rem 1.875rem 0 1.875rem;
}

.header {
  display: flex;
  justify-content: space-between;
}

.title {
  font-weight: bold;
}

.subTitle {
  font-size: 0.875rem;
}
