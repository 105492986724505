.statusBar {
  width: 100%;
  padding: 0.8rem;
  display: flex;
  justify-content: center;
  column-gap: 1.5rem;
  align-items: center;
}

.verticalHr {
  width: 1px;
  background-color: var(--shade-25);
  height: 1rem;
}

.viewReportButton {
  padding: 0;
}
