.invisibleButton {
  appearance: none;
  background: transparent;
  border: none;
  top: 0;
  right: 0;
  outline: none;
  z-index: 8000;
  margin: 0;
  padding: 0;
  color: inherit;
}

.invisibleButton:hover {
  opacity: 0.5;
  cursor: pointer;
}

.trashButton {
  color: var(--pink-base);
}

.calendarBackdrop {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

.calendarBackdrop > div {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25%;
  margin-left: -25%;
}

.specialInfoContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 2fr;
}

.specialTitleRow {
  display: flex;
  justify-content: space-between;
}

.marginTop {
  margin-top: 1rem;
}
