.columnHeader {
  font-size: 1rem;
  font-weight: bold;
}

.phoneInputWrapper {
  display: flex;
  position: relative;
}

.phoneNumberInput {
  margin-left: 1rem;
  width: auto;
}

.form {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 1224px) {
  .form {
    flex-direction: row;
  }

  .formColumn {
    width: 45%;
  }

  .formColumn:not(:first-child) {
    margin-left: 5rem;
  }
}

.inputGroup {
  display: grid;
  grid-template-columns: 0.4fr 0.7fr;
  padding: 0.75rem 0;
  align-items: center;
}

.paddingTop {
  padding-top: 1.5625rem;
}

.phoneInputWrapper > input + div {
  position: absolute;
  bottom: -21px;
}
