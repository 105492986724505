.tabsContainer {
  margin-top: 0.5rem;
}

.tab {
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
}

.tabIcon {
  display: flex;
  align-self: center;
}
