.somerows {
  display: flex;
}

.table {
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  text-align: left;
}

.toleranceSetting {
  width: 50%;
}

.toleranceSettingIcon {
  color: var(--accent-base);
  cursor: pointer;
}

.actionTargetType {
  display: flex;
  align-items: center;
  padding-left: 35%;
}

.actionTargetType label {
  margin-right: 1rem;
  text-transform: capitalize;
}
