.root {
  height: 100%;
  padding: 0 2rem;
}

.content {
  height: 100%;
}

.stepsContainer {
  padding-bottom: 3rem;
}

.title {
  font-weight: 600;
  padding-bottom: 1rem;
}
