.divider {
  width: 100%;
  margin-top: 1em;
  margin-bottom: 2em;
  border: 1px solid #dfe1e6;
  margin-left: -3.125em;
  margin-right: -3.125em;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cardHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contentContainer {
  width: 100%;
  display: flex;
}

.columnContainer {
  margin-left: 5rem;
  width: 40%;
}

.column {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.icon {
  color: var(--accent-base);
  padding-left: 1.25rem;
}

.cardTitle {
  font-weight: bold;
  width: 100%;
}

.timeTableTitle {
  color: var(--accent-base);
  cursor: pointer;
}

.link {
  color: var(--accent-base);
  cursor: pointer;
  font-weight: bold;
}

.value {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  line-height: 1.5;
  font-size: 0.75rem;
}

.boldText {
  font-weight: bold;
  color: black;
}

.cardContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bottomOfCard {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}

.workingModeButtonsContainer {
  display: flex;
  justify-content: space-between;
}

.lastConfigUpdateContainer {
  margin-top: 1rem;
  border-top: solid thin var(--ambient-basePlus75);
  padding-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 0 1rem;
  font-size: 0.75rem;
}

.lastConfigUpdate {
  display: flex;
  justify-content: flex-end;
  gap: 0 1rem;
}

.noLineBreak {
  white-space: nowrap;
}

.recordInfoTooltip {
  display: flex;
  flex-direction: column-reverse;
}

.controlCardNotes {
  margin-left: 5.25rem;
  height: min-content;
}

.paddingRight {
  padding-right: 0.5rem;
}

.dividerLine {
  border-bottom: 1px solid var(--ambient-basePlus75);
  margin: 0.5rem 0;
}

.subText {
  color: var(--ambient-basePlus25);
}

.doubleColumn {
  display: flex;
}

.marginRight {
  margin-right: 0.5rem;
}

.sopDeviation {
  font-size: 0.675rem;
  color: var(--ambient-basePlus25);
}

.redText {
  color: var(--pink-base);
}

.greyText {
  color: var(--ambient-basePlus50);
}

.streamTimestampText {
  color: var(--medium-base);
  font-size: 0.675rem;
  font-style: italic;
  margin-left: 0.25rem;
}

.updateSentButton {
  display: flex;
  align-items: center;
}

.loader {
  margin-left: 0.5rem;
}

.RunConfigTestWarning {
  padding: 1.5rem 3rem;
}
