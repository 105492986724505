.timetableVisualizationBlock {
  box-sizing: border-box;
  padding-left: 0.3em;
  cursor: default;
}

.filledBlock {
  background: #00a1af;
  outline: 0.1em solid white;
  color: white;
}

.unfilledBlock {
  background: white;
  outline: 0.1em solid #00a1af;
  color: #00a1af;
}

.hideTextOverflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tooltip {
  display: block;
}

.absolute {
  position: absolute;
}
