.root {
  padding: 0.5rem;
  font-size: 0.75rem;
  background-color: var(--turquoise-basePlus75);
  display: flex;
}

.boldText {
  font-weight: bold;
  color: black;
}

.paddingRight {
  padding-right: 0.5rem;
}
