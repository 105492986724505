.root {
  display: flex;
  flex-direction: row;
  position: relative;
}

.treeContainer {
  flex-basis: 350px;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  overflow: hidden;
  margin-bottom: -3rem;
  padding-bottom: 3rem;
  overflow-y: scroll;
  box-shadow: 5px 5px 7px -1px var(--shade-10);
}

.innerTreeContainer {
  width: 100%;
}

.innerTreeContainer > div:last-child {
  padding-bottom: 2rem;
}

.tilesContainer {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: -3rem;
  padding-bottom: 3rem;
  margin-right: -3rem;
  padding-right: 3rem;
  padding-left: 1rem;
  overflow-y: scroll;
}

.actionButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.lastRefreshContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5rem;
}

.lastRefreshButton {
  padding: 0.375rem 1rem 0.375rem 0.75rem;
}

.floatingLastRefreshButtonContainer {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  transition: opacity 0.2s ease-in-out;
  z-index: 2;
}

.floatingLastRefreshButton {
  padding: 0.5rem;
  min-width: auto;
}

.lastRefreshIcon {
  margin-right: 0.3125rem;
}

.lastRefreshTime {
  color: var(--shade-50);
  font-size: 0.75rem;
}
