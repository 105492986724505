.tooltip {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tooltipRoot {
  min-width: 8rem;
}

.tooltipTitle {
  text-align: left;
  font-weight: bold;
  margin-bottom: 0.5rem;
  font-size: 10.5pt;
}

.tooltipTableWidth {
  width: 30%;
}

.tableText {
  background-color: #333e48;
  color: white;
  font-size: 10pt;
  font-weight: 300;
  text-align: left;
  border-collapse: collapse;
  min-width: 14rem;
}

.tableText thead tr th {
  font-weight: 300;
}

.tableText thead {
  border-bottom: 0.5px solid white !important;
}

.marginLeft {
  margin-left: 0.5rem;
}

.title {
  font-weight: 400;
}

.text {
  left: 10% !important;
}
