.mainCardTitle {
  margin-top: 1rem;
  padding-bottom: 1rem;
}

.displayColumns {
  display: flex;
  flex-direction: row;
}
/* .flexRow {
  width: 100%;
  display: flex;
  flex-direction: row;
} */

.rightGrid {
  display: grid;
  grid-template-rows: 4rem 4rem 4rem;
  margin-left: 3rem;
}

.firstRow {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 2fr 2fr 2fr 2fr;
  height: 0.75rem;
}

.secondRow {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 2fr 3fr 3fr;
  height: 0.75rem;
}

.thirdRow {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 2fr 3fr 3fr;
  height: 0.75rem;
}
