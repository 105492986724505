.header {
  display: flex;
  flex: 1;
}

.headerRow {
  display: flex;
  margin-bottom: 0.5rem;
}

.filters {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
