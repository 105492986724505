.label {
  font-size: 0.75rem;
  margin-bottom: 0.5em;
}
.sopTitle {
  font-weight: bold;
}
.label:last-of-type {
  margin-bottom: 0;
}
.timeTableRow {
  font-size: 0.7rem;
}
.opHoursValue {
  margin-top: 0.3125rem;
}
.createNewSopText {
  font-size: 0.75rem;
  cursor: pointer;
}
