.tooltipTitle {
  font-style: italic;
  font-size: 12px;
  color: #ffffff;
}

.overflowTag {
  color: #ffffff;
  font-size: 10px;
}

.tooltipTime {
  padding-bottom: 0.3em;
  margin-bottom: 0.3em;
  border-bottom: 1px solid grey;
  color: #2bddcf;
  font-size: 15px;
}

.noBorder {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.thermostatInfo {
  color: #d8d8d8;
  font-size: 10px;
}

.thermostatInfoPadding {
  padding-bottom: 0.2em;
}
