.filesUploadContent {
  margin: 1rem 0;
}

.dragAndDrop {
  height: 5rem;
}

.flexRight {
  display: flex;
  justify-content: flex-end;
}

.listItem {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.browseFiles {
  display: block;
  cursor: pointer;
}

.browseFilesText {
  color: var(--accent-base);
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
}

.dragAndDropComp {
  background: #eaebec;
  box-shadow: 0 0 0 0.5rem #eaebec;
}

.allignBox {
  display: flex;
}
