.messageContainer {
  height: 100%;
  display: flex;
  align-items: center;
}

.submitButtonContainer {
  width: 100%;
  height: 50px;
  display: flex;
  column-gap: 0.5rem;
}

.submitButton {
  flex-grow: 1;
}

.trailingSpace {
  width: 1.5rem;
}
