.content {
  padding: 3rem;
}

.cardGrid {
  display: grid;
  grid-template-columns: 1fr 4fr;
}

.cardTitle {
  padding-top: 0.125rem;
  margin-left: 0.9375rem;
}

.textAlignCenter {
  text-align: center;
}

.statusContainer {
  padding: 0 3em 3em 3em;
}
