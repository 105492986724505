.lightChartFilter {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
}

.liveLuxReadingContainer {
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  margin-top: 1.25rem;
  border-bottom: 1px solid var(--ambient-basePlus75);
  padding-bottom: 1rem;
}

.label {
  margin-left: 0.75rem;
  font-weight: 600;
}

.liveValue {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.zoomResetContainer {
  margin-left: 0.5rem;
}

.historicHeader {
  font-weight: bold;
}
