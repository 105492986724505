.root {
  margin-top: 0.25rem;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 17rem);
  grid-auto-rows: min-content;
  grid-auto-flow: dense;
  grid-gap: 1.25rem;
}

.prompt {
  grid-column: 1 / -1;
  text-align: center;
  margin-bottom: 0.5rem;
}

.cardContainer {
  grid-column: 1 / -1;
}

.fields {
  margin: 2rem 1.875rem 0;
  display: flex;
  flex-direction: row;
  gap: 0.6125rem 1rem;
}

.accessToOrg {
  width: 8.5rem;
}

.organizationList {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.switchOrgButton {
  text-align: left;
}

.switchOrgButton:disabled {
  color: var(--ambient-base);
}

.orgIcon {
  margin-right: 1rem;
}
