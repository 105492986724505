:root {
  --label-line-height: 2rem;
}

.treeNodeContainer:not(:last-child) {
  border-left: 1px solid var(--shade-50);
}

.treeNodeContainer:not(:last-child) > .treeNode > .nodeVerticalLine {
  height: var(--label-line-height);
}

.treeNodeContainer:last-child > .treeNode > .nodeVerticalLine {
  height: calc(var(--label-line-height) / 2);
  border-left: 1px solid var(--shade-50);
}

.treeNode {
  display: flex;
  flex-direction: row;
}

.nodeHorizontalLine {
  width: 1rem;
  height: calc((var(--label-line-height) / 2) - 1px);
  margin-right: 0.5rem;
  border-bottom: 1px solid var(--shade-50);
}

.nodeCheckbox {
  position: relative;
}

/* to be removed when checkboxes are in use */
.checkboxFill {
  position: absolute;
  top: calc(50% - 0.5rem);
  left: 0;
  width: 1rem;
  height: 1rem;
  background-color: var(--shade-50);
  border-radius: 50%;
}

.nodeTitle {
  color: var(--ambient-base);
  font-weight: 600;
  font-size: 0.8rem;
  margin: 0.5rem;
  line-height: calc(var(--label-line-height) - 1rem);
}

div.nodeTitle span.nodeTitle {
  margin: 0;
}

.nodeTitleTooltip {
  font-weight: normal;
  font-size: 0.75rem;
  width: 100%;
  white-space: normal;
  line-height: 1rem;
}

.clickableNodeTitle {
  cursor: pointer;
}

.clickableNodeTitle:hover {
  color: var(--accent-base);
}

.subTree {
  padding-left: 2rem;
}
