.tableHeader {
  grid-template-columns: auto;
  justify-content: space-between;
}

.skeletonIconContainer {
  display: flex;
}

.skeletonIconTextContainer {
  display: flex;
  flex-direction: column;
  margin-left: 0.75rem;
}

/* .marginTop {
  margin-top: 0.25rem;
} */

.dropdownMenu {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.dropdownMenuButton {
  width: 20%;
}

.marginTop {
  margin-top: 1rem;
}

.filterLevelThree {
  position: relative;
}

/* .subHeader {
  display: none;
} */

.subHeader {
  padding: -1rem 3rem 0 3rem;
  display: flex;
  justify-content: space-between;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 10px;
  color: #000000;
  position: absolute;
}

/* div[class*='Table_container'] {
  padding-top: 10px;
  border-top: 1px solid var(--ambient-basePlus75);
} */

/* .filterContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  /* margin-bottom: 1rem; 
} */

.leftContent {
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  font-size: 0.875rem;
}

.headerHeading {
  color: #333e48;
  font-weight: 700;
  display: flex;
  justify-content: flex-end;
  font-size: 1.25rem;
  padding-right: 0.5rem;
}

.filterContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* margin-bottom: 1rem; */
}
